import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePlanPageStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "header" | "plansBtn"
>((theme) =>
  createStyles({
    header: {
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(4),
    },
    plansBtn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    root: {
      margin: `${theme.spacing(3)} auto`,
    },
  })
);
