import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { SignInPage } from "@pricing/pages/SignInPage/SignInPage";
import { UnauthorizedPage } from "@pricing/pages/UnauthorizedPage/UnauthorizedPage";
import { LoggedOutRoute } from "@pricing/routes/LoggedOutRoute";
import {
  campaignsPath,
  loginCallbackPath,
  signInPath,
  unauthorizedPath,
} from "@pricing/routes/routePaths";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const LoggedOutRoutes: FC = () => {
  const {
    authState: { isAuthenticated, isPending },
  } = useOktaAuth();

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (!isPending) {
            if (isAuthenticated) {
              return <Redirect to={signInPath()} />;
            } else {
              return <Redirect to={campaignsPath()} />;
            }
          }
        }}
      />
      <LoggedOutRoute exact path={signInPath()} component={SignInPage} />
      <LoggedOutRoute
        exact
        path={unauthorizedPath()}
        component={UnauthorizedPage}
      />
      <LoggedOutRoute
        exact
        path={loginCallbackPath()}
        component={LoginCallback}
      />
    </Switch>
  );
};
