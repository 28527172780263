import { useRhToggleStyles } from "@design-system/components/RhToggle/RhToggle.style";
import { Switch } from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";

interface RhToggleProps {
  id?: string;
  name?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?(event: ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

export const RhToggle: FC<RhToggleProps> = ({
  id = "",
  checked = false,
  name = "",
  disabled = false,
  onChange = () => {},
  className = "",
}: RhToggleProps) => {
  const classes = useRhToggleStyles();

  return (
    <Switch
      className={className}
      data-testid="RhToggle__Switch"
      id={id}
      name={name}
      classes={{
        checked: classes.checked,
        root: classes.input,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  );
};
