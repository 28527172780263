import { useRhFlashProviderStyles } from "@design-system/components/RhFlashProvider/RhFlashProvider.style";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import React, { FC } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RhFlashProviderProps extends SnackbarProviderProps {}

export const RhFlashProvider: FC<RhFlashProviderProps> = (
  props: RhFlashProviderProps
): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const classes = useRhFlashProviderStyles();

  return (
    <SnackbarProvider
      hideIconVariant
      maxSnack={3}
      autoHideDuration={5000}
      dense={isMobile}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
      {...props}
    />
  );
};
