import { api } from "@common/api/api";
import { campaignPromosApi } from "@common/api/campaignPromosApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { IdType } from "@common/types/apiTypes";
import { CampaignType } from "@common/types/campaignTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  RhSelectOption,
  SetAsyncOptionsType,
} from "@design-system/components/RhSelect/RhSelect";
import { RhSelectField } from "@design-system/components/RhSelectField/RhSelectField";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { PricingButton } from "@pricing/components/PricingButton/PricingButton";
import { CAMPAIGN_ALREADY_EXISTS_ERROR } from "@pricing/constants/campaign.constant";
import { useGetAllUtilities } from "@pricing/hooks/useGetAllUtilities";
import React, { FC } from "react";
import { Form } from "react-final-form";

interface CampaignFormValues {
  campaignSlug: string;
  utility: IdType;
  promo: IdType;
}

export const CampaignFormValidator = generateValidationErrorCollector<CampaignFormValues>(
  {
    campaignSlug: [isRequired],
    utility: [isRequired],
  }
);

interface CampaignFormProps {
  handleSuccess: (campaign: CampaignType) => void;
}

export const AddCampaignForm: FC<CampaignFormProps> = ({ handleSuccess }) => {
  const { requestMonitor, data: utilities } = useGetAllUtilities();
  const flash = useRhFlash();

  const fetchCampaignPromoOptions = (setOptions: SetAsyncOptionsType) => {
    campaignPromosApi
      .list()
      .then((campaignPromos) => {
        const options = campaignPromos.map(({ code, id }) => ({
          label: code,
          value: id,
        }));

        setOptions(options);
      })
      .catch((error: RhApiError) => {
        flash.error(
          "There was an issue fetching campaign promos. Please try again later."
        );
        setOptions();
      });
  };
  const onSubmit = ({ campaignSlug, utility, promo }: CampaignFormValues) => {
    const createParams = { campaignSlug, utilityId: utility, promoId: promo };

    api.pricing.campaigns
      .create(createParams)
      .then((response) => {
        handleSuccess(response);
      })
      .catch((error: RhApiError) => {
        if (error.data?.errorCode === CAMPAIGN_ALREADY_EXISTS_ERROR) {
          flash.error("This campaign slug and utility combo already exists");
        } else {
          flash.error("Error creating campaign. Please try again.");
        }
      });
  };

  if (requestMonitor.didFail) {
    flash.error("There was an issue creating a campaign. Please try again");
  }

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (!utilities) {
    return null;
  }

  const utilityOptions: RhSelectOption[] = utilities.map((utility) => ({
    label: utility.name,
    value: utility.id,
  }));

  return (
    <Form<CampaignFormValues>
      onSubmit={onSubmit}
      validate={CampaignFormValidator}
      initialValues={{}}
      render={({ handleSubmit }) => (
        <Box clone width="100%">
          <form
            noValidate
            onSubmit={handleSubmit}
            data-testid="addCampaignForm"
          >
            <Box display="flex" marginBottom={2}>
              <Box padding={2} flex={1}>
                <RhTextField name="campaignSlug" placeholder="Campaign Slug">
                  Campaign Slug
                </RhTextField>
              </Box>
              <Box padding={2} flex={1}>
                <RhSelectField
                  name="utility"
                  label="Utility"
                  options={utilityOptions}
                />
              </Box>
              <Box padding={2} flex={1}>
                <RhSelectField
                  name="promo"
                  label="Campaign Promo"
                  asyncOptions={fetchCampaignPromoOptions}
                />
              </Box>

              <Box padding={2} flex={1}>
                <PricingButton type="submit">Done</PricingButton>
              </Box>
            </Box>
          </form>
        </Box>
      )}
    />
  );
};
