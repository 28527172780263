import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { CircularProgress } from "@material-ui/core";
import React, { FC } from "react";

export const CIRCULAR_PROGRESS_DEFAULT_SIZE = 40;

interface RhCircularProgressProps {
  className?: string;
  marginBottom?: number;
  marginTop?: number;
  size?: number;
}

export const RhCircularProgress: FC<RhCircularProgressProps> = ({
  className,
  marginBottom,
  marginTop,
  size,
}) => (
  <RhFlexBox
    alignItems="center"
    justifyContent="center"
    width="100%"
    marginBottom={marginBottom ?? 2}
    marginTop={marginTop ?? 2}
    className={className}
  >
    <CircularProgress
      style={{
        height: size ?? CIRCULAR_PROGRESS_DEFAULT_SIZE,
        width: size ?? CIRCULAR_PROGRESS_DEFAULT_SIZE,
      }}
    />
  </RhFlexBox>
);
