import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";

type ShowFlash = (
  message: SnackbarMessage,
  options?: OptionsObject
) => SnackbarKey | undefined;

export interface RhFlashProviderContext {
  error: ShowFlash;
  success: ShowFlash;
  close(key?: SnackbarKey): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmptyObject(arg: any) {
  return Object.keys(arg).length === 0 && arg.constructor === Object;
}

export function useRhFlash(): RhFlashProviderContext {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function showFlash(message: SnackbarMessage, options?: OptionsObject) {
    let finalMessage = message;

    if (
      finalMessage === "" ||
      finalMessage === undefined ||
      finalMessage === null ||
      isEmptyObject(finalMessage)
    ) {
      return;
    }
    if (Array.isArray(message)) {
      finalMessage = message.join(". ").replace("..", ".");
    } else if (typeof message === "object") {
      finalMessage = JSON.stringify(finalMessage);
    }

    if (Array.isArray(message)) {
      finalMessage = message.join("\n");
    }

    return enqueueSnackbar(finalMessage, {
      ...options,
    });
  }

  return {
    error: (message: SnackbarMessage, options?: OptionsObject) => {
      return showFlash(message, {
        ...options,
        variant: "error",
      });
    },
    success: (message: SnackbarMessage, options?: OptionsObject) => {
      return showFlash(message, {
        ...options,
        variant: "success",
      });
    },
    close: closeSnackbar,
  };
}
