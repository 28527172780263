import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  fontBold,
  fontBoldItalic,
  fontLight,
  fontLightItalic,
  fontMedium,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
} from "@design-system/theme/avertaFont";
import {
  blue,
  darkPurple,
  green,
  grey,
  purple,
  red,
  trueWhite,
} from "@design-system/theme/palette.colors";
import {
  INPUT_ACTIVE_UNDERLINE_HEIGHT,
  UNIT_OF_SPACING,
} from "@design-system/theme/style.constant";
import { typography } from "@design-system/theme/typography";
import { createMuiTheme } from "@material-ui/core/styles";

const spacing = (num: number) => num * UNIT_OF_SPACING;

const palette = {
  primary: darkPurple,
  secondary: purple,
  tertiary: blue,
  error: red,
  success: green,
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[200], // not official
  },
  grey,
  background: {
    default: trueWhite,
  },
};

export const rhythmTheme = createMuiTheme({
  palette,
  spacing: UNIT_OF_SPACING,
  typography,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          fontLight,
          fontLightItalic,
          fontRegular,
          fontRegularItalic,
          fontMedium,
          fontMediumItalic,
          fontBold,
          fontBoldItalic,
        ],
        ul: {
          "list-style": "none",
          padding: 0,
          margin: 0,
        },
        a: {
          textDecoration: "none",
          color: darkPurple.main,
          cursor: "pointer",

          "&:not(.MuiButton-root):hover": {
            color: darkPurple[300],
            textDecoration: "underline",
          },
          "&:not(.MuiButton-root):active": {
            color: darkPurple[700],
            textDecoration: "underline",
          },
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: trueWhite,
        borderColor: darkPurple["300"],
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "none",
        zIndex: 100,
        "&:active": {
          backgroundColor: darkPurple["100"],
          boxShadow: "none",
        },
        "&:hover&:active": {
          backgroundColor: darkPurple["100"],
          boxShadow: "none",
        },
        "&:hover": {
          backgroundColor: darkPurple["50"],
        },
      },
      label: {
        color: palette.primary["500"],
        boxShadow: "none",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: palette.grey["200"],
      },
      light: {
        backgroundColor: palette.grey["100"],
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.text.secondary,
        fontSize: 12,
        fontWeight: FontWeight.Bold,
        "&$focused": {
          color: palette.primary.main,
        },
        "&$error": {
          color: red.main,
        },
        "&$disabled": {
          color: palette.grey[400],
        },
      },
      shrink: {
        transform: "translate(0, 1.5px)",
      },
    },
    MuiInputBase: {
      root: {
        "&$focused": {
          "& svg": {
            color: palette.primary.main,
          },

          "&$error": {
            "& svg": {
              color: palette.text.primary,
            },
          },
        },
        "&$disabled": {
          color: palette.grey[400],
        },
      },
    },
    MuiInput: {
      root: {
        borderBottomColor: grey[300],
        caretColor: palette.primary.main,
        "& .MuiIconButton-root": {
          position: "relative",
          top: -6,
        },
        "& .MuiIconButton-root:focus": {
          backgroundColor: "rgba(69, 0, 165, 0.04)",
        },
        "&$disabled": {
          borderBottomColor: grey[400],
          color: palette.grey[400],
          "-webkit-text-fill-color": grey[400],
        },
      },
      input: {
        fontSize: 16,
        marginTop: spacing(1),
        padding: `0 ${spacing(1.5)}px ${spacing(1.5)}px 0`,
        "&::placeholder": {
          // MUI lightens the placeholder text a bit, so this value gets us close to grey[400]
          color: grey[900],
        },
        "&[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
      underline: {
        "&:before": {
          // when not interacting
          borderBottomColor: palette.grey[200],
        },
        "&$disabled": {
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: palette.grey[400],
        },
        "&$error": {
          borderBottomWidth: INPUT_ACTIVE_UNDERLINE_HEIGHT,
          borderBottomColor: palette.error.main,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottomWidth: INPUT_ACTIVE_UNDERLINE_HEIGHT,
          borderBottomColor: palette.primary.main,
        },
        "&:after": {
          borderBottomWidth: INPUT_ACTIVE_UNDERLINE_HEIGHT,
          // when focused
          borderBottomColor: palette.primary.main,
        },
        "&.Mui-disabled": {
          color: palette.grey[400],
        },
      },
    },
    MuiPaper: {
      root: {},
    },
    MuiTableContainer: {
      root: {
        marginBottom: spacing(4),
      },
    },
    MuiTableCell: {
      head: {
        color: palette.text.secondary,
        textTransform: "capitalize",
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-child(2n+1)": {
          backgroundColor: grey[50],
        },
      },
      head: {
        "&:nth-child(2n+1)": {
          backgroundColor: "inherit",
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        "&$h1": {
          marginBottom: spacing(4),
        },
        "&$h2": {
          marginBottom: spacing(2),
        },
        "&$h3": {
          marginBottom: spacing(2),
        },
        "&$body1": {
          marginBottom: 12,
        },
        "&$body2": {
          marginBottom: 12,
        },
        "&$caption": {
          marginBottom: 12,
        },
        "&$subtitle1": {
          marginBottom: 16,
        },
        "&$subtitle2": {
          marginBottom: 16,
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&:focus": {
          outline: "revert !important",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "transparent",
        padding: `${spacing(2)}px ${spacing(3)}px`,
        fontSize: 16,
        lineHeight: "14px",
        borderRadius: 1000,
        "&$contained": {
          "&$disabled": {
            color: grey[500],
            backgroundColor: grey[50],
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
        },
        "&$text": {
          "&$disabled": {
            color: grey[400],
          },
        },
        textTransform: "none",
        whiteSpace: "nowrap",
      },
      text: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "&:hover": {
          backgroundColor: grey[50],
        },
        "&:hover:active": {
          backgroundColor: grey[100],
        },
      },
      contained: {
        padding: `${spacing(2)}px ${spacing(6)}px`,
        color: grey[500],
        backgroundColor: grey[50],
        "&:hover": {
          backgroundColor: grey[100],
          "&$disabled": {
            backgroundColor: grey[50],
          },
        },
      },
      containedPrimary: {
        color: trueWhite,
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.light,
        },
        "&:hover:active": {
          backgroundColor: palette.primary.dark,
          "&$disabled": {
            backgroundColor: grey[50],
          },
        },
      },
      outlined: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
        backgroundColor: trueWhite,
        "&:hover": {
          backgroundColor: darkPurple[50],
        },
        "&:hover:active": {
          backgroundColor: darkPurple[100],
        },
      },
      sizeSmall: {
        fontSize: 14,
        padding: `${spacing(1.5)}px ${spacing(2)}px`,
        lineHeight: "10px",
      },
      sizeLarge: {
        fontSize: 18,
        padding: `${spacing(2.5)}px ${spacing(4)}px`,
        lineHeight: "18px",
      },
    },
    MuiMobileStepper: {
      root: {
        paddingBottom: spacing(2),
        paddingTop: spacing(2),
      },
    },
    // MuiStepLabel classes CSS Api are not powerful enough
    MuiStepLabel: {
      root: {
        color: grey[200],
      },
      label: {
        fontSize: 10,
        letterSpacing: 0.01,
        color: grey[200],

        "&$alternativeLabel": {
          fontWeight: 600,
          marginTop: 6,
        },

        "&$active": {
          color: palette.primary.main,
        },

        "&$completed": {
          color: palette.primary.main,
        },
      },
    },
    MuiStepConnector: {
      root: {
        backgroundColor: palette.grey[200],
      },
      active: {
        backgroundColor: palette.primary.main,
      },
      completed: {
        backgroundColor: palette.primary.main,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
    },
    MuiCardContent: {
      root: {
        padding: `${spacing(3)}px ${spacing(3)}px ${spacing(2)}px ${spacing(
          3
        )}px`,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiCardActions: {
      root: {
        padding: `0 ${spacing(3)}px ${spacing(3)}px ${spacing(3)}px`,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
      },
      dense: {
        paddingTop: 0,
        paddingBottom: spacing(1),
      },
    },
    MuiListItemIcon: {
      root: {
        color: palette.text.primary,
        minWidth: 36,
      },
    },
    MuiListItemText: {
      primary: {
        margin: 0,
      },
    },
    MuiSwitch: {
      root: {
        alignItems: "center",
      },
      switchBase: {
        color: grey[100],
      },
      track: {
        height: "20px",
        borderRadius: "10px",
      },
      thumb: {
        position: "relative",
        "&:after": {
          content: '""',
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: trueWhite,
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      icon: {
        color: palette.text.primary,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "default",
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiFab: {
      size: "small",
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: "body1",
      },
    },
  },
});
