import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { MaskedDate } from "imask";

// plugin to allow strict date parsing
dayjs.extend(customParseFormat);

const dateOfBirthMask = new MaskedDate({
  mask: Date,
  min: new Date(1900, 0, 1),
  max: new Date(),
  pattern: "m/d/Y0",
});

export const formatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  return dateOfBirthMask.value.replace(/\./g, "/");
};

export const unformatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  const parsedDate = dayjs(dateOfBirthMask.value, "MM/DD/YYYY", true);

  return parsedDate.isValid() ? parsedDate.format(ISO8601_DATE_FORMAT) : "";
};

export const formatDateOfBirthFromDatabase = (value: string) => {
  const parsedDate = dayjs(value, ISO8601_DATE_FORMAT, true);

  return parsedDate.isValid() ? parsedDate.format("MM/DD/YYYY") : "";
};

export const formatMaskNumber = (value: string) => {
  return value.length > 4 ? `***${value.slice(-4)}` : "";
};

export const formatPhoneNumber = (digits: string): string => {
  const phoneString: string =
    digits?.length === 11 ? digits?.substr(1) : digits;
  const areaCode = phoneString?.substr(0, 3);
  const prefix = phoneString?.substr(3, 3);
  const suffix = phoneString?.substr(6, 4);

  return `(${areaCode}) ${prefix}-${suffix}`;
};
