import { IdType } from "@common/types/apiTypes";
import { EDIMessageType, PremiseType } from "@common/types/ediTypes";
import {
  AllSupportedPortalLanguages,
  SupportedPortalLanguage,
} from "@common/types/languageTypes";
import { MeterType } from "@common/types/meterTypes";
import { OrderType } from "@common/types/orderTypes";

export const MOVE_IN = "MOVE_IN";
export const SWITCH = "SWITCH";

export const AllEnrollmentTypes = [MOVE_IN, SWITCH] as const;

export type EnrollmentType = typeof AllEnrollmentTypes[number];

export type CustomerActivationStatusType = "PROVISIONED" | "ACTIVE";

export interface CustomerType {
  activePremise: PremiseType;
  activePremiseAddress: AddressType;
  autopay: boolean;
  averagedBilling: boolean;
  averagedBillingMonthlyCharge?: number;
  billingAccountId: string;
  billingAccountNumber: string;
  billingPaymentMethodId: string;
  confirmedStartDate?: string;
  dateOfBirth: string;
  ediMessages: EDIMessageType[];
  email: string;
  enrollmentStatus: string;
  enrollmentType: EnrollmentType;
  esiId?: string;
  etfAmount?: string;
  etfCreditAmountPoints: number;
  etfCreditStatus?: string;
  firstName: string;
  fullName: string;
  hasHistoricUsage: boolean;
  hasPlacedOrder: boolean;
  id: IdType;
  invoiceByEmail: boolean;
  invoiceByPrint: boolean;
  languagePreference: SupportedPortalLanguage;
  lastName: string;
  lifeSupport: boolean;
  mailingAddress?: AddressFormType;
  meter: MeterType;
  oktaId: string;
  orders: OrderType[];
  phone: string;
  pickedBillingDueDay: number | null;
  prospectId?: IdType | null;
  referralToFriendCode: string;
  serviceEndDate: string;
  serviceStartDate: string;
  source: CustomerSourceType;
}
export type CustomerUpdatePostDataType = Partial<CustomerType>;
export type CustomerListItemType = Pick<
  CustomerType,
  | "id"
  | "fullName"
  | "firstName"
  | "lastName"
  | "email"
  | "enrollmentStatus"
  | "enrollmentType"
  | "confirmedStartDate"
>;
export interface CustomerAuthenticationPostDataType {
  oktaId: string;
}
export type CustomerSearchResponseType = CustomerType[];

export type LanguagePreferenceType = typeof AllSupportedPortalLanguages[number];

export interface AddressFormType {
  addressLine1: string;
  unitNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface AddressType {
  deliveryLine1: string;
  city: string;
  state: string;
  zipCode: string;
  lastLine: string;
}

export interface CustomerBillingPreferences {
  invoiceByPrint: boolean;
  autopay: boolean;
  averagedBilling: boolean;
  pickedBillingDueDay: number | null;
  applyEtfPoints?: boolean;
  etfAmount?: string;
}

export type EDIServiceOrderActionTypes =
  | "switch_hold_create"
  | "switch_hold_remove"
  | "switch_cancel"
  | "disconnect"
  | "reconnect"
  | "resubmit_enrollment";

export interface EDIMessagePostDataType {
  actionName:
    | "switch_hold_create"
    | "switch_hold_remove"
    | "switch_cancel"
    | "disconnect"
    | "reconnect"
    | "resubmit_enrollment";
}

export interface ChannelSubscriptionStatus {
  unsubscribedChannelIds: string[];
}

// These values are defined by the Iterable API
// https://support.iterable.com/hc/en-us/articles/204780579-API-Overview-and-Sample-Payloads-#subscribe-a-user-to-a-single-channel-message-type-or-list
export type SubscriptionGroupType =
  | "messageChannel"
  | "messageType"
  | "emailList";

export interface UpdateSubscriptionParams {
  action: "subscribe" | "unsubscribe";
  subscriptionGroup: SubscriptionGroupType;
  channelId: string;
}

export type OktaIdTokenType = {
  authorizeUrl: string;
  claims: { sub: string; email: string; groups: string[] };
  clientId: string;
  expiresAt: number;
  idToken: string;
  issuer: string;
  scopes: string[];
};

// these strings match up with the SOURCE_CHOICES defined on the customer backend model
export enum CustomerSourceType {
  Rhythm = "RHYTHM",
  Olaf = "OLAF",
}
export interface SaveACHRequestType {
  publicToken: string;
  plaidAccountId: string;
}
