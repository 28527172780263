import { translations } from "@common/i18n/common.en.i18n";
import { globalIntl } from "@common/i18n/globalIntl";
import dayjs from "dayjs";
import { IntlShape, createIntl } from "react-intl";

export const generateTranslations = (): IntlShape => {
  const locale = "en";

  const intl = createIntl({
    locale,
    messages: translations,
  });

  dayjs.locale(locale);

  Object.assign(globalIntl, intl);

  return intl;
};
