import { generateAuthorizationHeader } from "@common/services/generateAuthorizationHeader";
import axios, { AxiosRequestConfig } from "axios";
import { decamelizeKeys } from "humps";

import {
  camelizeResponseKeys,
  decamelizeRequestKeys,
  sentryErrorInterceptor,
  toRhythmError,
} from "./ajaxInterceptors";

const MULTIPART_FORM_BOUNDARY =
  "--RhythmFormBoundary63c5979328c44e2c869349443a94200--";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    accept: "application/json",
  },
  transformRequest: [decamelizeRequestKeys],
  transformResponse: [camelizeResponseKeys],
  withCredentials: true,
});

export const axiosMultiPartFormInstance = axios.create({
  headers: {
    "Content-Type": `multipart/form-data; boundary=${MULTIPART_FORM_BOUNDARY}`,
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  sentryErrorInterceptor
);
axiosInstance.interceptors.response.use((response) => response, toRhythmError);

// Most axios config values are common, but app- or environment-driven values can be merged in with this function
// This allows the common lib to have reference to axiosInstance, while giving final say to each app
export function connectApi(config: AxiosRequestConfig) {
  Object.assign(axiosInstance.defaults, config);
  Object.assign(axiosMultiPartFormInstance.defaults, config);
}

interface AjaxInterface {
  get<ResponseType>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
  getFile<ResponseType>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<ResponseType, DataType = Record<string, any>>(
    url: string,
    data: DataType
  ): Promise<ResponseType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postFile<ResponseType, DataType = Record<string, any>>(
    url: string,
    data: DataType
  ): Promise<ResponseType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<ResponseType, DataType = Record<string, any>>(
    url: string,
    data: DataType
  ): Promise<ResponseType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<ResponseType, DataType = Record<string, any>>(
    url: string,
    data: DataType
  ): Promise<ResponseType>;
  destroy<ResponseType>(url: string): Promise<ResponseType>;
}

export const ajax: AjaxInterface = {
  get: (url, config) =>
    axiosInstance
      .get(url, {
        ...config,
        headers: generateAuthorizationHeader(),
        params: decamelizeKeys(config?.params || {}),
      })
      .then((res) => res.data),
  getFile: (url, config) =>
    axiosInstance
      .get(url, {
        ...config,
        headers: generateAuthorizationHeader(),
        params: decamelizeKeys(config?.params || {}),
        responseType: "blob",
      })
      .then((res) => res.data),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: (url: string, data: any) =>
    axiosInstance
      .post(url, data, {
        headers: generateAuthorizationHeader(),
      })
      .then((res) => res.data),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postFile: (url: string, data: any) => {
    return axiosMultiPartFormInstance
      .post(url, data, {
        headers: generateAuthorizationHeader(),
      })
      .then((res) => res.data);
  },
  patch: (url, data) =>
    axiosInstance
      .patch(url, data, {
        headers: generateAuthorizationHeader(),
      })
      .then((res) => res.data),
  put: (url, data) =>
    axiosInstance
      .put(url, data, {
        headers: generateAuthorizationHeader(),
      })
      .then((res) => res.data),
  destroy: (url) =>
    axiosInstance
      .delete(url, {
        headers: generateAuthorizationHeader(),
      })
      .then((res) => res.data),
};
