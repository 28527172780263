import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { CampaignPromoType } from "@common/types/campaignTypes";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AddCampaignPromoForm } from "@pricing/components/AddCampaignPromoForm/AddCampaignPromoForm";
import { useGetAllCampaignPromos } from "@pricing/hooks/useGetAllCampaignPromos";
import React, { FC, useState } from "react";

export const campaignPromosTableColumns: (keyof CampaignPromoType)[] = [
  "id",
  "code",
  "oneTimeValue",
  "recurringValue",
  "expirationDate",
];

export const CampaignPromosPage: FC = () => {
  const flash = useRhFlash();
  const [showForm, setShowForm] = useState<boolean>(false);

  const {
    requestMonitor,
    data: campaignPromos,
    error,
    triggerRefetch,
  } = useGetAllCampaignPromos();

  if (requestMonitor.didFail || !campaignPromos) {
    if (error) {
      flash.error("Unable to get campaign promos. Please try again.");
    }
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <RhFlexBox marginBottom={2}>
          <RhTypography variant="h1">Campaign Promos</RhTypography>
          <RhFlexBox marginLeft={2}>
            <RhButton
              onClick={() => {
                setShowForm(!showForm);
              }}
              color="primary"
              size="small"
              fullWidth={false}
            >
              {showForm ? "Close" : "+ Add Campaign Promo"}
            </RhButton>
          </RhFlexBox>
        </RhFlexBox>
      </Grid>
      {showForm && (
        <RhFlexBox marginY={2}>
          <AddCampaignPromoForm
            handleSuccess={() => {
              triggerRefetch();
              setShowForm(false);
            }}
          />
        </RhFlexBox>
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {campaignPromosTableColumns.map((column) => (
                <TableCell key={column}>
                  {column === "expirationDate"
                    ? "Reward Bonus Deadline"
                    : convertCamelCaseToSentenceCase(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignPromos.map((campaignPromo) => (
              <TableRow key={campaignPromo.id}>
                {campaignPromosTableColumns.map((column) => (
                  <TableCell key={`${column}${campaignPromo.id}`}>
                    {campaignPromo[column] || NOT_APPLICABLE_ABBREVIATION}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
