import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";

export class AuthClient {
  public authClient: OktaAuth;

  constructor(oktaConfig: OktaAuthOptions) {
    this.authClient = new OktaAuth(oktaConfig);
  }

  public signIn(email: string, password: string): Promise<void> {
    return this.authClient
      .signIn({
        username: email,
        password,
      })
      .then((transaction) => {
        const { status, sessionToken } = transaction;

        if (status !== "SUCCESS" || !sessionToken) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw transaction;
        }
        return this.redirectToRedirectUri(sessionToken);
      });
  }

  public redirectToRedirectUri(sessionToken: string): Promise<void> {
    return this.authClient.token.getWithRedirect({
      sessionToken,
    });
  }
}
