import { campaignPromosApi } from "@common/api/campaignPromosApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isAllCaps,
  isRequired,
  isValidOneOrBothRequired,
} from "@common/forms/validators";
import { CampaignPromoType } from "@common/types/campaignTypes";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { DateField } from "@pricing/components/DateField/DateField";
import { PricingButton } from "@pricing/components/PricingButton/PricingButton";
import dayjs from "dayjs";
import React, { FC } from "react";
import { Form } from "react-final-form";

interface CampaignPromoFormValues {
  code: string;
  oneTimeValue: string;
  recurringValue: string;
  expirationDate: string;
}

export const CampaignPromoFormValidator = generateValidationErrorCollector<CampaignPromoFormValues>(
  {
    code: [isRequired, isAllCaps],
    oneTimeValue: [
      (value, { recurringValue }) =>
        isValidOneOrBothRequired(value, recurringValue),
    ],
    recurringValue: [
      (value, { oneTimeValue }) =>
        isValidOneOrBothRequired(value, oneTimeValue),
    ],
  }
);

interface CampaignPromoFormProps {
  handleSuccess: (campaignPromo: CampaignPromoType) => void;
}

export const AddCampaignPromoForm: FC<CampaignPromoFormProps> = ({
  handleSuccess,
}) => {
  const flash = useRhFlash();

  const onSubmit = ({
    code,
    oneTimeValue,
    recurringValue,
    expirationDate,
  }: CampaignPromoFormValues) => {
    campaignPromosApi
      .create({ code, oneTimeValue, recurringValue, expirationDate })
      .then(handleSuccess)
      .catch(() => {
        flash.error("Error creating campaign promo. Please try again.");
      });
  };

  return (
    <Form<CampaignPromoFormValues>
      onSubmit={onSubmit}
      validate={CampaignPromoFormValidator}
      render={({ handleSubmit }) => (
        <Box clone width="100%">
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" marginBottom={2}>
              <Box padding={2} flex={1}>
                <RhTextField name="code" placeholder="Promo Code">
                  Code
                </RhTextField>
              </Box>

              <Box padding={2} flex={1}>
                <RhTextField
                  name="oneTimeValue"
                  placeholder="One Time Value"
                  type="number"
                >
                  One Time Value
                </RhTextField>
              </Box>

              <Box padding={2} flex={1}>
                <RhTextField
                  name="recurringValue"
                  placeholder="Recurring Value"
                  type="number"
                >
                  Recurring Value
                </RhTextField>
              </Box>
              <Box padding={2} flex={1}>
                <DateField
                  data-testid="AddCampaignPromoForm__expirationDateField"
                  name="expirationDate"
                  label="Reward Bonus Deadline"
                  minDate={dayjs().add(1, "day").toDate()}
                  clearable
                />
              </Box>
              <Box padding={2} flex={1}>
                <PricingButton type="submit">Submit</PricingButton>
              </Box>
            </Box>
          </form>
        </Box>
      )}
    />
  );
};
