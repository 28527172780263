import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { DIALOG_BUTTON_WIDTH } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useRhDialogContentWithButtonsStyles = makeStyles<
  typeof rhythmTheme,
  "button" | "buttonsContainter"
>((theme) =>
  createStyles({
    button: {
      marginBottom: theme.spacing(1.5),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
        width: DIALOG_BUTTON_WIDTH,
      },
    },
    buttonsContainter: {
      [theme.breakpoints.only("xs")]: {
        alignItems: "center",
        flexDirection: "column-reverse",
      },
    },
  })
);
