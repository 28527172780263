import { useRhFullScreenDialogStyles } from "@design-system/components/RhFullScreenDialog/RhFullScreenDialog.style";
import { Box, Dialog, DialogProps, Fab, Fade, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface RhFullScreenDialogProps
  extends Omit<DialogProps, "fullscreen" | "TransitionComponent" | "onClose"> {
  onClose: () => void;
}

export const RhFullScreenDialog: FC<RhFullScreenDialogProps> = ({
  onClose,
  fullScreen,
  children,
  ...props
}) => {
  const classes = useRhFullScreenDialogStyles();
  const Backdrop: FC<{ open: boolean }> = ({ open }) => {
    return (
      <Fade in={open}>
        <Box className={classes.backdrop} onClick={onClose} />
      </Fade>
    );
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      BackdropComponent={Backdrop}
      onClose={onClose}
      classes={{
        paperFullScreen: fullScreen ? classes.root : classes.rootNarrow,
      }}
      {...props}
    >
      <Fab
        color="default"
        onClick={onClose}
        aria-label="close"
        classes={{ root: classes.closeButton }}
      >
        <CloseIcon />
      </Fab>
      <Box className={classes.content}>{children}</Box>
    </Dialog>
  );
};
