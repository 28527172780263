import { FontWeight } from "@design-system/enums/fontWeight.enum";
import boldItalic from "@design-system/fonts/Averta/bold-italic.woff2";
import bold from "@design-system/fonts/Averta/bold.woff2";
import lightItalic from "@design-system/fonts/Averta/light-italic.woff2";
import light from "@design-system/fonts/Averta/light.woff2";
import regularItalic from "@design-system/fonts/Averta/regular-italic.woff2";
import regular from "@design-system/fonts/Averta/regular.woff2";
import mediumItalic from "@design-system/fonts/Averta/semibold-italic.woff2";
import medium from "@design-system/fonts/Averta/semibold.woff2";
import { makeFontFamily } from "@design-system/theme/helpers/makeFontFamily";
import { FONT_NAME } from "@design-system/theme/typography";

enum FontType {
  normal = "normal",
  italic = "italic",
}

export const fontBold = makeFontFamily(
  bold,
  FONT_NAME,
  FontWeight.Bold,
  FontType.normal
);
export const fontMedium = makeFontFamily(
  medium,
  FONT_NAME,
  FontWeight.Medium,
  FontType.normal
);
export const fontRegular = makeFontFamily(
  regular,
  FONT_NAME,
  FontWeight.Regular,
  FontType.normal
);
export const fontLight = makeFontFamily(
  light,
  FONT_NAME,
  FontWeight.Light,
  FontType.normal
);

export const fontBoldItalic = makeFontFamily(
  boldItalic,
  FONT_NAME,
  FontWeight.Bold,
  FontType.italic
);
export const fontMediumItalic = makeFontFamily(
  mediumItalic,
  FONT_NAME,
  FontWeight.Medium,
  FontType.italic
);
export const fontRegularItalic = makeFontFamily(
  regularItalic,
  FONT_NAME,
  FontWeight.Regular,
  FontType.italic
);
export const fontLightItalic = makeFontFamily(
  lightItalic,
  FONT_NAME,
  FontWeight.Light,
  FontType.italic
);
