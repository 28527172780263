import queryString from "querystring";

import { IdType } from "@common/types/apiTypes";
import {
  SupportedPortalLanguage,
  englishLanguageCode,
} from "@common/types/languageTypes";

export const urls = {
  customers: {
    index: () => "/api/customers/",
    create: () => "/api/customers/",
    retrieve: (id: IdType) => `/api/customers/${id}/`,
    update: (id: IdType) => urls.customers.retrieve(id),
    me: () => "/api/customers/me/",
    available: () => "/api/customers/available/",
    search: (query: string) =>
      `/api/customers/search/?search=${encodeURIComponent(query)}`,
    accountSummary: {
      retrieve: (id: IdType) => `/api/customers/${id}/account_summary/`,
      averagedBillingMonthlyCharge: (id: IdType) =>
        `/api/customers/${id}/account_summary/averaged_billing_monthly_charge/`,
    },
    unsubscribedChannelIds: (id: IdType) =>
      `/api/customers/${id}/unsubscribed_channel_ids/`,
    updateSubscriptionStatus: (id: IdType) =>
      `/api/customers/${id}/update_subscription_status/`,
    invoice: (customerId: IdType, invoiceId: IdType = "") =>
      `/api/customers/${customerId}/invoice/${invoiceId}/`,
    payments: {
      create: (id: IdType) => `/api/customers/${id}/payments/`,
    },
    ediMessages: {
      create: (id: IdType) => `/api/customers/${id}/edi_messages/`,
    },
    password: {
      getStateToken: () => "/api/customers/get_set_password_state_token/",
      set: () => "/api/customers/set_password/",
      sendResetEmail: () => "/api/customers/reset_password_email/",
      reset: () => "/api/customers/reset_password/",
    },
    plaid: {
      saveACH: (id: IdType) =>
        `${urls.customers.retrieve(id)}ach-payment-method/`,
      linkToken: (id: IdType) =>
        `${urls.customers.retrieve(id)}billing-link-token/`,
    },
    loyaltyProgram: {
      retrieve: (id: IdType) => `/api/customers/${id}/rewards/`,
      redeem: (id: IdType) => `/api/customers/${id}/loyalty-program/redeem/`,
    },
  },
  edi: {
    meterAvailability: (): string => "/api/edi/meter/availability/",
    serviceStartDates: (): string => "/api/edi/service/start_dates",
    enrollmentStatuses: (): string => "api/edi/enrollment_statuses",
    setEnrollmentStatus: (id: IdType) =>
      `/api/edi/premises/${id}/set_enrollment_status/`,

    utilities: {
      index: (): string => "/api/edi/utilities",
    },
  },
  ediProvider: {
    getAllowableInboundEDIActions: (id: IdType) =>
      `/api/edi-provider/allowable-inbound-actions?customer_id=${id}`,
    triggerEdiGeneration: () => "/api/edi-provider/generate-edi/",
  },
  pricing: {
    offers: {
      list: (): string => "/api/pricing/offers/",
      search: (): string => "/api/pricing/offers/search",
      create: () => urls.pricing.offers.list(),
      retrieve: (id: IdType) => `/api/pricing/offers/${id}/`,
      update: (id: IdType) => urls.pricing.offers.retrieve(id),
      availability: (): string => "/api/pricing/offers/availability/",
      efl: (
        id: IdType,
        locale: SupportedPortalLanguage = englishLanguageCode
      ): string =>
        `/api/pricing/offers/efl/?${queryString.stringify({
          offer_id: id,
          locale,
        })}`,
    },
    plans: {
      index: (): string => "/api/pricing/plans",
      download: (): string => "/api/pricing/plans/download/",
      upload: (): string => "/api/pricing/plans/upload/",
      archive: (id: IdType) => `/api/pricing/plans/${id}/archive/`,
    },
    campaigns: {
      index: (): string => "/api/pricing/campaigns/",
      create: (): string => urls.pricing.campaigns.index(),
      retrieve: (id: IdType) => `/api/pricing/campaigns/${id}/`,
    },
  },
  prospects: {
    create: () => "/api/prospects/",
    retrieve: (id: IdType) => `/api/prospects/${id}/`,
    update: (id: IdType) => urls.prospects.retrieve(id),
    creditScoreEvaluation: () => "/api/prospects/credit_score_evaluation/",
    billingLinkToken: (id: IdType) =>
      `/api/prospects/${id}/billing_link_token/`,
    createAch: () => "/api/prospects/create_ach/",
    trackEnteredSignup: (id: IdType) =>
      `/api/prospects/${id}/track_entered_signup/`,
    trackServiceUnavailable: (id: IdType) =>
      `/api/prospects/${id}/track_service_unavailable/`,
  },
  utilities: {
    search: () => "/api/edi/utilities/search/",
  },
};
