import { PlanType, PlansResponseType } from "@common/types/planTypes";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { usePlanSelectorStyles } from "@pricing/components/PlanSelector/PlanSelector.style";
import React, { FC } from "react";

interface PlanSelectorProps {
  onSelect: (plan: PlanType) => void;
  plans: PlansResponseType;
  selectedPlan?: PlanType;
}

export type PlanFieldNameType =
  | "title"
  | "descriptionEn"
  | "termMonths"
  | "product"
  | "recProduct"
  | "bundled"
  | "zuoraRatePlanId"
  | "archived";

interface HeaderType {
  display: string;
  field: PlanFieldNameType;
}

const headers: HeaderType[] = [
  {
    field: "title",
    display: "Title",
  },
  {
    field: "descriptionEn",
    display: "Description",
  },
  {
    field: "termMonths",
    display: "Term (Months)",
  },
  {
    field: "product",
    display: "Product",
  },
  {
    field: "recProduct",
    display: "Rec Product",
  },
  {
    field: "bundled",
    display: "Bundled",
  },
  {
    field: "archived",
    display: "Archived",
  },
];

export const PlanSelector: FC<PlanSelectorProps> = ({
  onSelect,
  plans,
  selectedPlan,
}) => {
  const classes = usePlanSelectorStyles();

  const displayPlanValue = (plan: PlanType, field: PlanFieldNameType) => {
    if (field === "bundled") {
      return plan[field] ? "Yes" : "No";
    }
    if (field === "archived") {
      return plan[field] ? (
        <RhTypography color="error">Yes</RhTypography>
      ) : (
        "No"
      );
    }

    return plan[field];
  };

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell key="checkbox">&nbsp;</TableCell>
              {headers.map(({ display, field }) => (
                <TableCell key={field}>
                  <RhTypography variant="body2">{display}</RhTypography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-testid="choosePlanForm__tableBody">
            {plans.map((plan) => {
              return (
                <TableRow key={plan.id}>
                  <TableCell>
                    <RhRadioInput
                      checked={selectedPlan?.id === plan.id}
                      onChange={() => {
                        onSelect(plan);
                      }}
                      value={plan.title}
                    />
                  </TableCell>
                  {headers.map(({ field }) => (
                    <TableCell key={`${field}-${plan.id}`}>
                      {displayPlanValue(plan, field)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
