import { ISO8601_DATE_FORMAT_REGEX } from "@common/constants/date.constant";
import { EnrollmentType, MOVE_IN, SWITCH } from "@common/types/customerTypes";
import { InferType, boolean, mixed, number, object, string } from "yup";

export const createCustomerRequestSchema = object({
  customerData: object({
    autopay: boolean().required(),
    billingPaymentMethodId: string().nullable().defined(),
    csrSignUp: boolean().required(),
    dateOfBirth: string().matches(ISO8601_DATE_FORMAT_REGEX).required(),
    depositAmount: number().min(0).required(),
    email: string().email().required(),
    enrollmentType: mixed<EnrollmentType>().oneOf([MOVE_IN, SWITCH]).required(),
    esiId: string().required(),
    firstName: string().required(),
    invoiceByPrint: boolean().required(),
    languagePreference: string().matches(/en|es/).required(),
    lastName: string().required(),
    offersnapshotId: string().required(),
    phone: string().required(),
    prospectId: string().nullable().defined(),
    referralFromFriendCode: string().nullable().defined(),
    sendMarketingPromos: boolean().required(),
    serviceAddress: object({
      addressLine1: string().required(),
      unitNumber: string().defined(),
      city: string().required(),
      state: string().required(),
      zipCode: string().required(),
    }),
    serviceStartDate: string().matches(ISO8601_DATE_FORMAT_REGEX).required(),
  }),
});

export type CreateCustomerRequest = InferType<
  typeof createCustomerRequestSchema
>;
