import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { CampaignType } from "@common/types/campaignTypes";
import { RhApiError } from "@common/types/errorTypes";
import { UserType } from "@common/types/userTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { useOktaAuth } from "@okta/okta-react";
import { AddCampaignForm } from "@pricing/components/AddCampaignForm/AddCampaignForm";
import { useCampaignPageStyles } from "@pricing/pages/CampaignsPage/CampaignsPage.style";
import { campaignOffersPath } from "@pricing/routes/routePaths";
import React, { useEffect, useState } from "react";

export const CampaignsPage = () => {
  const flash = useRhFlash();
  const classes = useCampaignPageStyles();
  const [
    { data, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<CampaignType[]>([]);

  const [addCampaignFormVisible, setAddCampaignFormVisible] = useState<boolean>(
    false
  );
  const [newCampaignId, setNewCampaignId] = useState<IdType>("");
  const [userInfo, setUserInfo] = useState<UserType>({} as UserType);

  const { authService } = useOktaAuth();

  useEffect(() => {
    authService.getUser().then((info: UserType) => {
      setUserInfo(info);
    });
  }, [authService]);

  useEffect(
    () => {
      setPending();

      api.pricing.campaigns
        .index()
        .then((campaignResponse: CampaignType[]) => {
          setSuccess(campaignResponse);
        })
        .catch((error: RhApiError) => {
          setFailure(error);
          flash.error("Error getting campaigns. Please refresh page.");
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newCampaignId]
  );

  const [hideExpired, setHideExpired] = useState<boolean>(true);

  const campaigns =
    data?.filter((campaign) => {
      if (hideExpired) {
        return campaign.active;
      }
      return true;
    }) ?? [];

  if (!requestMonitor.didSucceed && !campaigns) {
    return <RhCircularProgress />;
  }

  const handleAddCampaign = (campaign: CampaignType) => {
    setNewCampaignId(campaign.id);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <RhFlexBox className={classes.header}>
          <RhFlexBox className={classes.titleContainer}>
            <RhTypography variant="h1" className={classes.addCampaignHeader}>
              Campaign Management
            </RhTypography>
            <RhButton
              onClick={() => setAddCampaignFormVisible(!addCampaignFormVisible)}
              className={classes.addCampaignBtn}
              color="primary"
              size="small"
              fullWidth={false}
            >
              + Add Campaign
            </RhButton>
          </RhFlexBox>
          {userInfo && (
            <RhTypography variant="body1" className={classes.welcomeMessage}>
              {`Welcome, ${userInfo.email}`}
            </RhTypography>
          )}
        </RhFlexBox>
        <RhFlexBox className={classes.expired}>
          <RhTypography variant="body1">Hide Expired Campaigns</RhTypography>
          <RhToggle
            className={classes.expiredToggle}
            checked={hideExpired}
            onChange={() => {
              setHideExpired(!hideExpired);
            }}
          />
        </RhFlexBox>
      </Grid>
      <TableContainer
        data-testid="campaignsPage__TableContainer"
        component={Paper}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <RhTypography variant="body2">Campaign ID</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2">Campaign Slug</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2">Utility</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2"># of Offers</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2">Promo</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2">Status</RhTypography>
              </TableCell>
              <TableCell>
                <RhTypography variant="body2">Actions</RhTypography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => {
              return (
                <TableRow key={campaign.id}>
                  <TableCell>{campaign.id}</TableCell>
                  <TableCell>{campaign.campaignSlug}</TableCell>
                  <TableCell>{campaign.utility.name}</TableCell>
                  <TableCell>
                    {Boolean((campaign.offers || []).length) &&
                      campaign.offers.filter((offer) => Boolean(offer.active))
                        .length}
                  </TableCell>
                  <TableCell>{campaign.promo?.code}</TableCell>
                  <TableCell>{campaign.status}</TableCell>
                  <TableCell>
                    <RhLinkButton
                      href={campaignOffersPath(campaign.id)}
                      fullWidth={false}
                    >
                      <RhTypography variant="body2">Manage Offers</RhTypography>
                    </RhLinkButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {addCampaignFormVisible && (
        <RhFlexBox width="50%">
          <AddCampaignForm handleSuccess={handleAddCampaign} />
        </RhFlexBox>
      )}
    </Grid>
  );
};
