import { PriceMethodTypes } from "@common/types/offerTypes";
import { PlanType } from "@common/types/planTypes";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { calculatePriceFromGrossMargin } from "@pricing/utils/calculations.util";
import React, { FC, useEffect } from "react";
import { useField, useFormState } from "react-final-form";

interface PriceFieldFieldProps {
  name: string;
  plan: PlanType;
  placeholder: string;
}

export const PriceField: FC<PriceFieldFieldProps> = ({
  name,
  placeholder,
  plan,
  children,
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const { input } = useField(name);

  const readOnly = values.priceMethod === PriceMethodTypes.Margin;

  useEffect(() => {
    if (readOnly) {
      if (values.grossMargin) {
        input.onChange(calculatePriceFromGrossMargin(values.grossMargin, plan));
      } else {
        input.onChange("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.grossMargin, readOnly, plan]);

  return (
    <RhTextInput
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={readOnly}
      type="number"
      inputProps={{ min: "0", step: "0.01" }}
      {...input}
    >
      {children}
    </RhTextInput>
  );
};
