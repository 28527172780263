export const homePath = (): string => {
  return "/";
};

export const customerDetailPath = (customerId: string): string => {
  return `/customers/${customerId}`;
};

export const customersPath = (): string => {
  return "/customers";
};

export const campaignsPath = (): string => {
  return "/campaigns";
};

export const campaignOffersPath = (campaignId: string): string => {
  return `/campaign/${campaignId}/offers`;
};

export const campaignPromosPath = (): string => {
  return "/campaign-promos";
};

export const campaignPromoPath = (promoId: string): string => {
  return `/campaign-promos/${promoId}`;
};

export const newCampaignPromoPath = (): string => {
  return "/campaign-promos/new";
};

export const plansPath = (): string => {
  return "/plans";
};

export const signInPath = (): string => {
  return "/sign-in";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const logoutPath = (): string => {
  return "/logout";
};
