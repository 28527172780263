import { useRhDateInputStyles } from "@design-system/components/RhDateInput/RhDateInput.style";
import { ISO8601_DATE_FORMAT } from "@design-system/constants/date.constant";
import { DatePicker } from "@material-ui/pickers";
import { DatePickerProps } from "@material-ui/pickers/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { FC } from "react";

export interface RhDateInputProps
  extends Omit<DatePickerProps, "value" | "onChange"> {
  name: string;
  label: string;
  placeholder?: string;
  autoComplete?: string;
  value?: string | null;
  onChange?: (dateString: string | null) => void;
  hasErrors?: boolean;
  allowedDates?: string[];
  dateFormat?: string;
  todayLabel: string;
  okLabel: string;
  cancelLabel: string;
}

const createShouldDisableDate = (allowedDates: string[]) => {
  return (date: Dayjs | null) =>
    date ? !allowedDates.includes(date.format(ISO8601_DATE_FORMAT)) : true;
};

export const RhDateInput: FC<RhDateInputProps> = ({
  name,
  label,
  placeholder,
  autoComplete = "off",
  allowedDates,
  value = null,
  onChange,
  hasErrors,
  dateFormat = "MM/DD/YYYY",
  todayLabel,
  okLabel,
  cancelLabel,
  InputProps,
  ...restProps
}) => {
  const classes = useRhDateInputStyles();

  let allowedDateConfig = {};

  if (allowedDates) {
    const shouldDisableDate = createShouldDisableDate(allowedDates);

    allowedDateConfig = {
      minDate: allowedDates[0],
      maxDate: allowedDates[allowedDates.length - 1],
      shouldDisableDate,
    };
  }

  const valueAsDate =
    (value && dayjs(value, { format: ISO8601_DATE_FORMAT })) || null;

  const handleChange = (date: Dayjs | null) => {
    if (onChange) {
      onChange(date?.format(ISO8601_DATE_FORMAT) ?? null);
    }
  };

  return (
    <DatePicker
      fullWidth
      variant="dialog"
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      todayLabel={todayLabel}
      className={classes.root}
      label={label}
      InputLabelProps={{
        htmlFor: name,
        error: hasErrors,
      }}
      InputProps={{
        id: name,
        error: hasErrors,
        ...InputProps,
      }}
      format={dateFormat}
      autoComplete={autoComplete}
      placeholder={placeholder}
      readOnly={false}
      views={["date"]}
      value={valueAsDate}
      onChange={handleChange}
      {...allowedDateConfig}
      {...restProps}
    />
  );
};
