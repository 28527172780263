export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";
export const ISO8601_DATE_FORMAT_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
export const DOB_DATE_FORMAT = "MM/DD/YYYY";

export const SHORT_DATE_FORMAT = "M/D/YY";
export const SHORT_MONTH_DATE_FORMAT = "MM/DD";
export const SHORT_MONTH_WITH_HOUR_DATE_FORMAT = "MM/DD, ha";
export const ABBREVIATED_MONTH_DATE_FORMAT = "MMM. D";
export const ABBREVIATED_MONTH_WITH_HOUR_DATE_FORMAT = "MMM. D, ha";
export const DUE_DATE_FORMAT = "MMMM D";
export const LONG_DATE_TIME_FORMAT = "MM-DD-YY hh:mm A Z";
