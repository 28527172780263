import { PlanType } from "@common/types/planTypes";
import { AVERAGE_MONTHLY_USAGE } from "@pricing/constants/offer.constant";
import { toSigFig } from "@pricing/formatters/number.formatter";

const calculateUtilityCharges = (plan: PlanType, KWHUsage = 2000) => {
  const tdspKwhRate = parseFloat(plan.tdspPerUnitMwhAmount) / 1000;
  const tdspMonthlyCharge = parseFloat(plan.tdspCustomerChargeAmount);

  return tdspKwhRate + tdspMonthlyCharge / KWHUsage;
};

export const calculatePricePerMwh = (
  price2kMWH: number,
  plan: PlanType,
  KWHUsage: number
) => {
  const utilityCharges = calculateUtilityCharges(plan, KWHUsage);
  const grossMargin = calculateGrossMargin(price2kMWH, plan);
  const termedCogsAmountMwh = parseFloat(plan.termedCogsAmountMwh);
  const rhythmCharges = (grossMargin + termedCogsAmountMwh) / 1000;

  return toSigFig(rhythmCharges + utilityCharges);
};

export const calculateGrossMargin = (price: number, plan: PlanType) => {
  const utilityCharges = calculateUtilityCharges(plan, AVERAGE_MONTHLY_USAGE);
  const termedCogsAmountMwh = parseFloat(plan.termedCogsAmountMwh);
  const rhythmKwhRate = price - utilityCharges;

  return toSigFig(1000 * rhythmKwhRate - termedCogsAmountMwh);
};

export const calculateEnergyRateKWH = (price: number, plan: PlanType) => {
  const utilityCharges = calculateUtilityCharges(plan, AVERAGE_MONTHLY_USAGE);

  return toSigFig(price - utilityCharges);
};

export const calculateEnergyRateMWH = (price: number, plan: PlanType) => {
  return toSigFig(calculateEnergyRateKWH(price, plan) * 1000);
};

export const calculatePriceFromGrossMargin = (
  grossMargin: number,
  plan: PlanType
) => {
  const termedCogsAmountMwh = parseFloat(plan.termedCogsAmountMwh);
  const utilityCharges = calculateUtilityCharges(plan, AVERAGE_MONTHLY_USAGE);

  return toSigFig(
    grossMargin / 1000 + termedCogsAmountMwh / 1000 + utilityCharges
  );
};
