import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { CampaignType } from "@common/types/campaignTypes";
import { RhApiError } from "@common/types/errorTypes";
import { OfferType } from "@common/types/offerTypes";
import { PlanType, PlansResponseType } from "@common/types/planTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { OfferDetailsForm } from "@pricing/components/OfferDetailsForm/OfferDetailsForm";
import { PlanSelector } from "@pricing/components/PlanSelector/PlanSelector";
import React, { FC, useEffect, useState } from "react";

interface OfferFormProps {
  campaign: CampaignType | null;
  offer?: OfferType;
  handleSuccess: (offer: OfferType) => void;
}

export const OfferForm: FC<OfferFormProps> = ({
  campaign,
  handleSuccess,
  offer,
}) => {
  const [
    { data, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<PlansResponseType>([]);

  const [plan, setPlan] = useState<PlanType>();
  const flash = useRhFlash();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const plans: PlansResponseType = data || [];
  const campaignId: IdType | null = campaign?.id || null;
  const editMode = Boolean(offer);
  let params = {};

  if (editMode) {
    params = campaign ? { utilityId: campaign.utility.id } : {};
  } else {
    params = campaign
      ? { utilityId: campaign.utility.id, archived: false }
      : {};
  }

  useEffect(() => {
    setPending();
    api.pricing.plans
      .index(params)
      .then((plansResponseType: PlansResponseType) => {
        setSuccess(plansResponseType);
      })
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error("There was an issue getting the offers. Please try again.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (offer) {
      const selectedPlan = plans.find((p) => p.id === offer.planId);

      setPlan(selectedPlan);
    }
  }, [offer, plans]);
  if (requestMonitor.didFail) {
    return null;
  }

  return (
    <>
      {requestMonitor.isPending ? (
        <RhCircularProgress />
      ) : (
        <PlanSelector onSelect={setPlan} plans={plans} selectedPlan={plan} />
      )}

      {plan && (
        <OfferDetailsForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          campaignId={campaign!.id}
          campaignPromo={campaign?.promo}
          offer={offer}
          plan={plan}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};
