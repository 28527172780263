import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useRhRadioInputStyles = makeStyles<
  typeof rhythmTheme,
  "icon" | "radioRoot"
>((_theme) =>
  createStyles({
    icon: {
      color: "inherit",
      fontSize: 20,
    },
    radioRoot: {
      color: "inherit",
    },
  })
);
