import { RhApiError, RhApiErrorResponseData } from "@common/types/errorTypes";
import { captureException, withScope } from "@sentry/react";
import { AxiosError } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

export const decamelizeRequestKeys = (data: Record<string, unknown>) =>
  JSON.stringify(decamelizeKeys(data));

export const camelizeResponseKeys = (data: string) => {
  try {
    return camelizeKeys(JSON.parse(data));
  } catch {
    return data;
  }
};

export const shouldReportError = (error: AxiosError) => {
  // Ignore "forbidden" errors that usually mean a user is not logged in
  return error.response?.status !== 403;
};

export const sentryErrorInterceptor = (
  error: AxiosError
): Promise<AxiosError> => {
  if (shouldReportError(error)) {
    withScope((scope) => {
      const errorToCapture = error;
      const cancelled = error.response === undefined;
      const statusCode = error.response?.status;
      const errorResponse = error.response?.data || {};
      const { errors } = errorResponse;
      const { code } = error;
      const { baseURL, data, method, params, url } = error.config;
      const httpMethod = method?.toUpperCase();
      const errorData = {
        axiosCode: code,
        baseURL,
        data: JSON.stringify(data),
        errors: JSON.stringify(errors),
        method: httpMethod,
        params,
        statusCode,
        url,
      };

      errorToCapture.name = "API Error";
      errorToCapture.message = cancelled
        ? `${httpMethod} ${url} was cancelled`
        : `${httpMethod} ${url} returned ${statusCode}`;

      scope.setContext("API Request", errorData);
      captureException(errorToCapture);
    });
  }
  return Promise.reject<AxiosError>(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformErrorData = (data: any = {}) => {
  const errorData = data ?? {};

  let errorCode: string | null = errorData.errorId ?? null;

  if (Array.isArray(errorData?.errors)) {
    const [firstError] = errorData?.errors;

    errorCode = firstError?.code ?? errorCode;
  }

  const errors: { code: string; message: string }[] = errorData.errors ?? [];

  const transformedError: RhApiErrorResponseData = {
    errors: errors.map(({ code }) => ({ code })),
    errorCode: errorCode ?? null,
  };

  if (errorData.stateToken) {
    transformedError.stateToken = errorData.stateToken;
  }

  return transformedError;
};

export function toRhythmError(
  error: AxiosError<RhApiErrorResponseData>
): Promise<RhApiError> {
  const { response } = error;

  const {
    data: errorData,
    status,
    statusText,
    config: { baseURL, data, method, url, params },
  } = response || {
    config: {},
  };

  const transformedError: RhApiError = {
    data: transformErrorData(errorData),
    status,
    statusText,
    config: { baseURL, data, method, url, params },
  };

  return Promise.reject<RhApiError>(transformedError);
}
