import { RequestMonitor } from "@common/models/RequestMonitor.model";
import {
  AjaxStatuses,
  UseAjaxSetters,
  UseAjaxStateType,
} from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { Dispatch, SetStateAction, useState } from "react";

function successHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return (data: ResponseType) => {
    setState({
      requestMonitor: new RequestMonitor(AjaxStatuses.Success),
      data,
      error: null,
    });
  };
}
function failureHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return (error: RhApiError) => {
    setState((state) => ({
      requestMonitor: new RequestMonitor(AjaxStatuses.Failure),
      data: state.data,
      error,
    }));
  };
}
function pendingHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return () => {
    setState((state) => ({
      ...state,
      requestMonitor: new RequestMonitor(AjaxStatuses.Pending),
    }));
  };
}

const pristineUseAjaxState = {
  requestMonitor: new RequestMonitor(),
  data: null,
  error: null,
};

// Docs:
// https://www.notion.so/gotrhythm/7958d63be7bf494fa121fbaa57a5db34#f65e2fa4bdc84f0596578da17b0dd705

export const useAjaxState = <ResponseType>(
  initialData?: ResponseType,
  initialError?: RhApiError
): [UseAjaxStateType<ResponseType>, UseAjaxSetters<ResponseType>] => {
  const initialState: UseAjaxStateType<ResponseType> = {
    ...pristineUseAjaxState,
  };

  if (initialData) {
    initialState.data = initialData;
    initialState.requestMonitor = new RequestMonitor(AjaxStatuses.Success);
  }
  if (initialError) {
    initialState.error = initialError;
    initialState.requestMonitor = new RequestMonitor(AjaxStatuses.Failure);
  }
  const [state, setState] = useState<UseAjaxStateType<ResponseType>>(
    initialState
  );

  return [
    state,
    {
      setSuccess: successHandler<ResponseType>(setState),
      setFailure: failureHandler<ResponseType>(setState),
      setPending: pendingHandler<ResponseType>(setState),
    },
  ];
};
