export const generateAuthorizationHeader = () => {
  const oktaTokenStorage = localStorage.getItem("okta-token-storage");

  if (oktaTokenStorage) {
    const parsedOktaTokenStorage = JSON.parse(oktaTokenStorage);

    if (parsedOktaTokenStorage.accessToken) {
      const tokenValue = parsedOktaTokenStorage.accessToken.value;
      const { tokenType } = parsedOktaTokenStorage.accessToken;

      return { Authorization: `${tokenType} ${tokenValue}` };
    }
  }
  return {};
};
