import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useVersionStyles = makeStyles<
  typeof rhythmTheme,
  { isVisible?: boolean | undefined },
  "root"
>((theme) =>
  createStyles({
    root: ({ isVisible }) =>
      isVisible
        ? {
            color: theme.palette.error.main,
            fontWeight: FontWeight.Bold,
            left: 5,
            margin: theme.spacing(0),
            position: "fixed",
            top: 0,
            zIndex: 1101,
          }
        : {
            display: "none",
            height: 0,
            visibility: "hidden",
            width: 0,
          },
  })
);
