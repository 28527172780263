import { campaignPromosUrls } from "@common/api/campaignPromosUrls";
import { generateGetHook } from "@common/api/generateGetHook";
import { CampaignPromoType } from "@common/types/campaignTypes";
import { formatMonthDayYearDate } from "@common/utils/dataFormatters";
import { camelizeKeys } from "humps";

const sanitizeExpirationDate: (data: string) => CampaignPromoType[] = (
  rawData
) => {
  const campaignPromos = camelizeKeys(
    JSON.parse(rawData)
  ) as CampaignPromoType[];

  return campaignPromos.map((campaignPromo: CampaignPromoType) => {
    const { expirationDate } = campaignPromo;

    return {
      ...campaignPromo,
      expirationDate: expirationDate && formatMonthDayYearDate(expirationDate),
    };
  });
};

export const useGetAllCampaignPromos = generateGetHook<CampaignPromoType[]>(
  campaignPromosUrls.list,
  { transformResponse: sanitizeExpirationDate }
);
