import {
  RhDateInput,
  RhDateInputProps,
} from "@design-system/components/RhDateInput/RhDateInput";
import { Box } from "@material-ui/core";
import React, { FC } from "react";
import * as RFF from "react-final-form";

interface RhDateFieldProps
  extends Omit<RhDateInputProps, "todayLabel" | "okLabel" | "cancelLabel"> {
  name: string;
  label: string;
}

export const DateField: FC<RhDateFieldProps> = ({
  name,
  label,
  ...restProps
}) => {
  const {
    input: { value, type: unused, ...restInput },
    meta,
  } = RFF.useField<string>(name, {
    type: "date",
  });

  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const hasErrors = !meta.active && meta.touched && hasValidationError;

  return (
    <Box position="relative" width="100%">
      <RhDateInput
        hasErrors={hasErrors}
        label={label}
        value={value}
        placeholder={label}
        todayLabel="Today"
        okLabel="OK"
        cancelLabel="Cancel"
        {...restProps}
        {...restInput}
      />
    </Box>
  );
};
