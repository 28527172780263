import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhDateInputStyles = makeStyles<typeof rhythmTheme, "root">(
  (_theme) =>
    createStyles({
      root: {
        "& input": {
          fontWeight: FontWeight.Medium,
        },
        "&:hover input": {
          cursor: "pointer",
        },
      },
    })
);
