import { useVersionStyles } from "@common/components/Version/Version.style";
import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import dayjs from "dayjs";
import preval from "preval.macro";
import React, { FC, useState } from "react";

// This `preval` allows us to execute code at build time - think of it as
// javascript `eval` during the build.
// This allows us to stamp a built distribution with the build time.
// We might later use it to read a version number or SHA
const BUILD_TIMESTAMP = preval`module.exports = new Date().getTime();`;

const GIT_SHA = preval`
  const { execSync } = require('child_process');
  module.exports = execSync('git rev-parse --short HEAD', {
    encoding: 'utf-8',
  }).replace('\\n', "");
`;

const formatBuildTimestamp = () => {
  const lastUpdateMoment = dayjs.unix(BUILD_TIMESTAMP / 1000);

  return lastUpdateMoment.format("DD.MM.YYYY HH:mm:ss");
};

const Version: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const classes = useVersionStyles({ isVisible });

  if (!isVisible && getEnv() !== EnvTypes.production) {
    setIsVisible(true);
  }

  const version =
    `Environment: ${getEnv()}       ` +
    `git SHA: ${GIT_SHA}       ` +
    `Build Time: ${BUILD_TIMESTAMP} (${formatBuildTimestamp()})`;

  return <pre className={classes.root}>{version}</pre>;
};

export { Version };
