import { PriceMethodTypes } from "@common/types/offerTypes";
import { PlanType } from "@common/types/planTypes";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { calculateGrossMargin } from "@pricing/utils/calculations.util";
import React, { FC, useEffect } from "react";
import { useField, useFormState } from "react-final-form";

interface GrossMarginFieldProps {
  name: string;
  placeholder: string;
  plan: PlanType;
}

export const GrossMarginField: FC<GrossMarginFieldProps> = ({
  name,
  placeholder,
  plan,
  children,
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const { input } = useField(name);

  const readOnly = values.priceMethod === PriceMethodTypes.Fixed;

  // Run once at start to populate grossMargin
  useEffect(() => {
    if (!readOnly) {
      if (values.price && !values.grossMargin) {
        input.onChange(calculateGrossMargin(values.price, plan));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readOnly) {
      if (values.price) {
        input.onChange(calculateGrossMargin(values.price, plan));
      } else {
        input.onChange("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price, readOnly]);

  return (
    <RhTextInput
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={readOnly}
      type="number"
      {...input}
    >
      {children}
    </RhTextInput>
  );
};
