import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import {
  FULL_SCREEN_DIALOG_CONTENT_MAX_WIDTH,
  RH_HEADER_HEIGHT,
} from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useRhFullScreenDialogStyles = makeStyles<
  typeof rhythmTheme,
  "backdrop" | "content" | "closeButton" | "root" | "rootNarrow"
>((theme) =>
  createStyles({
    backdrop: {
      backgroundColor: "rgba(38, 31, 47, 0.65)",
      height: "100%",
      left: 0,
      position: "fixed",
      top: 0,
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(3),
      [theme.breakpoints.only("xs")]: {
        position: "fixed",
      },
    },
    content: {
      margin: "0 auto",
      maxWidth: `${FULL_SCREEN_DIALOG_CONTENT_MAX_WIDTH}px`,
      padding: `${theme.spacing(7)}px 0`,
      [theme.breakpoints.only("xs")]: {
        padding: `${theme.spacing(7)}px ${theme.spacing(3)}px`,
      },
    },
    root: {
      [theme.breakpoints.up("sm")]: {
        alignSelf: "flex-end",
        height: `calc(100% - ${theme.spacing(RH_HEADER_HEIGHT)}px)`,
      },
    },
    rootNarrow: {
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(10),
        width: "70%",
      },
    },
  })
);
