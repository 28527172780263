import { MaskedPattern } from "imask";

const phoneMask = new MaskedPattern({ mask: "000-000-0000" });

export const maskPhone = (value: string) => {
  phoneMask.value = value ?? "";
  return phoneMask.value;
};

export const unmaskPhone = (value: string) => {
  phoneMask.value = value ?? "";
  return phoneMask.unmaskedValue;
};
