import { api } from "@common/api/api";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { HTMLInputEvent } from "@design-system/types/domEventTypes";
import Input from "@material-ui/core/Input";
import React, { FC, useState } from "react";

export const PlansUploadDialog: FC = () => {
  const flash = useRhFlash();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [csvFile, setCsvFile] = useState<File>();

  const handleSubmit = async () => {
    if (csvFile) {
      try {
        const response = await api.pricing.plans.upload(csvFile);

        if (response) {
          setDialogIsOpen(false);
          const { size } = response;

          flash.success(
            `Plans Menu of size: ${size} was successfully uploaded.`
          );
        }
      } catch (error) {
        const errorMsg = "Failed to upload file. Please check your data.";

        flash.error(errorMsg);
      }
    }
  };

  const handleChange = (event: HTMLInputEvent) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];
      const { name } = file;
      const pattern = /.csv$/;

      if (pattern.test(name) === false) {
        flash.error("File upload must be a CSV File");
        setCsvFile(undefined);
        return;
      }
      setCsvFile(file);
    }
  };

  return (
    <>
      <RhDialog open={dialogIsOpen} onClose={() => {}} size="medium">
        <RhDialogTitle>Plans Upload</RhDialogTitle>
        <RhDialogContentWithButtons
          primaryButton={{
            label: "Save",
            onClick: () => {
              handleSubmit();
            },
          }}
          secondaryButton={{
            label: "Cancel",
            onClick: () => {
              setDialogIsOpen(false);
            },
          }}
        >
          <div>
            <form>
              <Input
                onChange={handleChange}
                type="file"
                id="planUploadInput"
                aria-label="planUploadInput"
              />
            </form>
          </div>
        </RhDialogContentWithButtons>
      </RhDialog>
      <RhButton
        color="primary"
        size="small"
        onClick={() => setDialogIsOpen(true)}
      >
        Upload Plans
      </RhButton>
    </>
  );
};
