import { FontWeight } from "@design-system/enums/fontWeight.enum";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {
  TypographyOptions,
  TypographyStyleOptions,
} from "@material-ui/core/styles/createTypography";

// This will output our default breakpoints to use below
const breakpoints = createBreakpoints({});

interface RhTypographyOptions extends TypographyOptions {
  body3: TypographyStyleOptions;
}

export const FONT_NAME = "Averta";
export const fontFamily = [
  FONT_NAME,
  "Helvetica Neue",
  "Arial",
  "sans-serif",
].join(",");

export const typography: RhTypographyOptions = {
  fontFamily,
  fontWeightLight: FontWeight.Light,
  fontWeightRegular: FontWeight.Regular,
  fontWeightMedium: FontWeight.Medium,
  fontWeightBold: FontWeight.Bold,
  fontSize: 16,
  htmlFontSize: 16,
  h1: {
    fontSize: 32,
    fontWeight: FontWeight.Medium,
    [breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  h2: {
    fontSize: 24,
    fontWeight: FontWeight.Regular,
  },
  h3: {
    fontSize: 20,
    fontWeight: FontWeight.Bold,
  },
  h4: {
    fontSize: 20,
    fontWeight: FontWeight.Regular,
  },
  body1: {
    fontSize: 14,
    fontWeight: FontWeight.Regular,
  },
  body2: {
    fontSize: 12,
    fontWeight: FontWeight.Regular,
    lineHeight: 1.3,
  },
  caption: {
    fontSize: 11,
    fontWeight: FontWeight.Bold,
  },
  body3: {
    fontSize: 10,
    fontWeight: FontWeight.Regular,
  },
  button: {
    fontSize: 16,
    fontWeight: FontWeight.Medium,
    textTransform: "capitalize",
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: FontWeight.Regular,
    lineHeight: 1.3,
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: FontWeight.Regular,
  },
};
