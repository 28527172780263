import { useAjaxState } from "@common/hooks/useAjaxState";
import { ajax } from "@common/services/ajax";
import {
  ParamURLMaker,
  SimpleURLMaker,
  UseAjaxStateType,
} from "@common/types/apiTypes";
import axios, { AxiosRequestConfig } from "axios";
import merge from "lodash/merge";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export const cancelMessage = "Operation canceled by the user.";

// Docs:
// https://www.notion.so/gotrhythm/7958d63be7bf494fa121fbaa57a5db34#da3c083b7ff043169a11262a9d85fb21

export interface GetHookApi<ResponseType>
  extends UseAjaxStateType<ResponseType> {
  triggerRefetch: () => void;
}

function resolveGetHookArguments<URLParams>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any
): { urlParams: URLParams; config: AxiosRequestConfig } {
  let urlParams: URLParams | undefined;
  let config: AxiosRequestConfig = {};

  if (args.length === 1 && typeof args[0] === "object") {
    // simple url maker, and a config was passed
    config = args[0] ?? {};
  } else if (args.length === 1) {
    // param url maker, and no config was passed
    [urlParams] = args;
  } else if (args.length === 2) {
    [urlParams] = args;
    config = args[1] ?? {};
  }

  return {
    urlParams: urlParams as URLParams,
    config,
  };
}

export function generateGetHook<ResponseType>(
  urlMaker: SimpleURLMaker,
  configDefaults?: AxiosRequestConfig
): (config?: AxiosRequestConfig) => GetHookApi<ResponseType>;
// eslint-disable-next-line no-redeclare
export function generateGetHook<ResponseType, URLParams extends string>(
  urlMaker: ParamURLMaker<URLParams>,
  configDefaults?: AxiosRequestConfig
): (
  urlParams: URLParams,
  config?: AxiosRequestConfig
) => GetHookApi<ResponseType>;
// eslint-disable-next-line no-redeclare
export function generateGetHook<ResponseType, URLParam>(
  ambiguousURLMaker: SimpleURLMaker | ParamURLMaker<URLParam>,
  configDefaults?: AxiosRequestConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]): GetHookApi<ResponseType> => {
    const { urlParams, config } = resolveGetHookArguments<URLParam>(
      ...Array.from(args)
    );

    const [refetch, triggerRefetch] = useState<boolean>(false);
    const [ajaxState, { setPending, setSuccess, setFailure }] = useAjaxState<
      ResponseType
    >();

    const source = axios.CancelToken.source();
    const cancelPrevRequest = () => {
      source.cancel(cancelMessage);
    };

    useDeepCompareEffect(() => {
      if (ajaxState.requestMonitor.isPending) {
        cancelPrevRequest();
      }

      setPending();

      ajax
        .get<ResponseType>(ambiguousURLMaker(urlParams), {
          ...merge({}, configDefaults, config),
          cancelToken: source.token,
        })
        .then(setSuccess)
        .catch(setFailure);
      return cancelPrevRequest;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams, config, refetch]);

    return {
      ...ajaxState,
      // this method allows callers to forcibly refetch the get request
      triggerRefetch: () => triggerRefetch(!refetch),
    };
  };
}
