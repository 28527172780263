import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { trueWhite } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhToggleStyles = rhMakeStyles<
  typeof rhythmTheme,
  "checked" | "input" | "switchBase" | "thumb" | "track"
>((theme) =>
  createStyles({
    checked: {
      "&$checked + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
    input: {
      height: "20px",
      padding: 0,
      width: "40px",
    },
    switchBase: {
      left: 1,
      padding: 0,
      top: 1,
    },
    thumb: {
      boxShadow: "none",
      color: trueWhite,
      height: "18px",
      width: "18px",
    },
    track: {
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
    },
  })
);
