import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { calculateInputErrorPosition } from "@design-system/theme/helpers/calculateInputErrorPosition";
import { Box, useTheme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input, { InputProps as IInputProps } from "@material-ui/core/Input";
import { InputBaseProps } from "@material-ui/core/InputBase";
import InputLabel, {
  InputLabelProps as IInputLabelProps,
} from "@material-ui/core/InputLabel";
import React, { FC, RefAttributes } from "react";

export interface RhTextInputProps extends InputBaseProps {
  name: string;
  placeholder: string;
  errorMessage?: string;
  InputLabelProps?: IInputLabelProps;
  InputProps?: IInputProps;
}

export const RhTextInput: FC<
  RhTextInputProps & RefAttributes<unknown>
> = React.forwardRef(
  (
    {
      className,
      name,
      error,
      errorMessage = "",
      InputLabelProps,
      InputProps,
      inputProps,
      children,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <FormControl fullWidth className={className}>
        <InputLabel
          shrink
          disableAnimation
          htmlFor={name}
          error={error}
          disabled={disabled}
          {...InputLabelProps}
        >
          {children}
        </InputLabel>
        <Input
          id={name}
          error={error}
          disabled={disabled}
          inputProps={{ ...inputProps, tabIndex: 0 }}
          ref={ref}
          {...InputProps}
          {...restProps}
        />
        <Box
          position="absolute"
          left={0}
          bottom={calculateInputErrorPosition(theme)}
        >
          <RhTypography
            color="error"
            variant="caption"
            fontWeight={FontWeight.Regular}
          >
            {error ? errorMessage : ""}
          </RhTypography>
        </Box>
      </FormControl>
    );
  }
);
