import { SecureRoute, useOktaAuth } from "@okta/okta-react";
import { CampaignPromosPage } from "@pricing/pages/CampaignPromosPage/CampaignPromosPage";
import { CampaignsPage } from "@pricing/pages/CampaignsPage/CampaignsPage";
import { OffersPage } from "@pricing/pages/OffersPage/OffersPage";
import { PlansPage } from "@pricing/pages/PlansPage/PlansPage";
import {
  campaignOffersPath,
  campaignPromosPath,
  campaignsPath,
  logoutPath,
  plansPath,
} from "@pricing/routes/routePaths";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

export const LoggedInRoutes: FC = () => {
  const { authService } = useOktaAuth();

  return (
    <Switch>
      <SecureRoute exact path={campaignsPath()} component={CampaignsPage} />
      <SecureRoute
        exact
        path={campaignOffersPath(":id")}
        component={OffersPage}
      />
      <SecureRoute
        exact
        path={campaignPromosPath()}
        component={CampaignPromosPage}
      />
      <SecureRoute exact path={plansPath()} component={PlansPage} />
      <Route
        exact
        path={logoutPath()}
        render={() => {
          authService.logout();
          return null;
        }}
      />
    </Switch>
  );
};
