import { EnvTypes } from "@common/enums/envTypes.enum";
import { rhWindow } from "@common/utils/rhWindow";

export function getEnv(): EnvTypes {
  const { host } = rhWindow.location;

  if (host.endsWith("staging.gotrhythm.com")) {
    return EnvTypes.staging;
  }
  if (host.endsWith("test.gotrhythm.com")) {
    return EnvTypes.test;
  }
  // Handle production after other environments or else test and staging will return as Production.
  if (host.endsWith(".gotrhythm.com")) {
    return EnvTypes.production;
  }

  return EnvTypes.local;
}

export function isLocal(): boolean {
  return getEnv() === EnvTypes.local;
}

export function isProduction(): boolean {
  return getEnv() === EnvTypes.production;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnvApplicationSettings<T = any>(
  allEnvApplicationSettings: Record<EnvTypes, T>
): T {
  const env = getEnv();

  return allEnvApplicationSettings[env];
}
