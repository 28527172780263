import { Box, BoxProps } from "@material-ui/core";
import React, { FC } from "react";

type RhFlexBoxProps = Omit<BoxProps, "display">;

export const RhFlexBox: FC<RhFlexBoxProps> = (props) => {
  return (
    <Box display="flex" {...props}>
      {props.children}
    </Box>
  );
};
