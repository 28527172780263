import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { useMobileRhHeaderStyles } from "@design-system/components/RhHeader/MobileRhHeader/MobileRhHeader.style";
import { RhHeaderProps } from "@design-system/components/RhHeader/RhHeader";
import { BurgerIcon, XCloseIcon } from "@design-system/icons";
import { IconButton, Slide } from "@material-ui/core";
import React, { FC, useState } from "react";

export const MobileRhHeader: FC<Omit<RhHeaderProps, "logo">> = ({
  items,
  divideLastItem,
}) => {
  const classes = useMobileRhHeaderStyles({ divideLastItem });
  const [isSlideOpen, setIsSlideOpen] = useState<boolean>(false);

  if (!items.length) {
    return null;
  }

  const toggleSlide = () => {
    setIsSlideOpen(!isSlideOpen);
  };

  const fullWidthDivider = (
    <li style={{ width: "100%" }}>
      <RhDivider light orientation="horizontal" />
    </li>
  );

  const listItems = (
    // onClick event only applies to mobile, so no need to add a11y key handlers
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <ul
      data-testid="RhHeader__list"
      className={classes.listMobile}
      onClick={toggleSlide}
    >
      {items.map((item, index) => {
        if (!item.key) {
          throw new Error(
            "Each list element passed in the props must have a key"
          );
        }

        return (
          <React.Fragment key={item.key}>
            {divideLastItem && index === items.length - 1 && fullWidthDivider}
            <li className={classes.item}>{item}</li>
          </React.Fragment>
        );
      })}
    </ul>
  );

  return (
    <>
      <div className={classes.iconWrapper}>
        <IconButton
          aria-label="toggle nav"
          onClick={toggleSlide}
          color="primary"
          className={classes.iconButton}
        >
          {isSlideOpen ? <XCloseIcon /> : <BurgerIcon />}
        </IconButton>
      </div>
      <Slide direction="right" in={isSlideOpen}>
        {listItems}
      </Slide>
    </>
  );
};
