import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const LOGO_WIDTH = "102px";

export const useRhHeaderStyles = makeStyles<
  typeof rhythmTheme,
  "container" | "divider" | "item" | "listContainer" | "logoContainer"
>((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      borderBottom: borders[100],
      flexDirection: "row",
      flexWrap: "nowrap",
      height: theme.spacing(RH_HEADER_HEIGHT),
      padding: 0,
    },
    divider: {
      height: "100%",
      marginRight: theme.spacing(3),
    },
    item: {
      "&.RhHeader__DividerItem": {
        marginRight: theme.spacing(3),
      },
      "&:hover": {
        color: "blue",
      },
      fontSize: 14,
      margin: `${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(
        2
      )}px 0`,
      whiteSpace: "nowrap",
    },
    listContainer: {
      alignItems: "center",
      display: "flex",
      flexGrow: 1,
      flexWrap: "nowrap",
      justifyContent: "flex-end",
      listStyle: "none",
    },
    logoContainer: {
      "& svg": {
        height: "100%",
        left: 0,
        top: 0,
        width: "100%",
      },
      "&:hover": {
        color: theme.palette.grey[300],
      },
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
      color: theme.palette.grey[500],
      height: theme.spacing(RH_HEADER_HEIGHT),
      marginLeft: theme.spacing(4.5),
      transition: "color 200ms",
      width: LOGO_WIDTH,
    },
  })
);
