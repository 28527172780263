import { borders } from "@design-system/constants/borders";
import { Dialog, DialogProps, useTheme } from "@material-ui/core";
import React, { FC } from "react";

interface RhDialogProps extends DialogProps {
  size?: "small" | "medium" | "large";
}

const sizeMap = {
  small: 400,
  medium: 430,
  large: 500,
};

export const RhDialog: FC<RhDialogProps> = ({
  children,
  size = "medium",
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <Dialog
      {...restProps}
      BackdropProps={{
        style: {
          background: theme.palette.grey[700],
          opacity: 0.65,
        },
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          border: borders[100],
          width: sizeMap[size],
        },
      }}
    >
      {children}
    </Dialog>
  );
};
