export const trueBlack = "#000";
export const trueWhite = "#FFF";

export const grey = {
  50: "#F5F5F7",
  100: "#EDEBF0",
  200: "#CCC9D1",
  300: "#ABA7B2",
  400: "#918D99",
  500: "#777380",
  600: "#5E5A66",
  700: "#45414C",
  800: "#2E2B33",
  900: "#171619",
};

export const purple = {
  main: "#9633FF",
  light: "#C085FF",
  dark: "#5A1F99",
  50: "#FAF5FF",
  100: "#F5EBFF",
  200: "#F2F2FF",
  300: "#C085FF",
  500: "#9633FF",
  700: "#5A1F99",
};

export const darkPurple = {
  main: "#4500A5",
  light: "#8F73E3",
  dark: "#290063",
  50: "#F6F5FC",
  100: "#ECEBFA",
  200: "#B1ABED",
  300: "#8F73E3",
  500: "#4500A5",
  700: "#290063",
};

export const blue = {
  main: "#50B0E5",
  50: "#EFF8FC",
  100: "#E0F1FA",
  300: "#99DAFF",
  500: "#50B0E5",
  700: "#1382C2",
};

export const red = {
  main: "#B30309",
  dark: "#C24842",
  500: "#B30309",
  700: "#C24842",
};

export const gold = {
  main: "#CDBA16",
};

export const green = {
  main: "#59C28A",
  50: "#EEF9F3",
  100: "#DEF3E8",
  300: "#A4EBC4",
  500: "#59C28A",
  700: "#3B9464",
};
