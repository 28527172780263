import { CampaignType } from "@common/types/campaignTypes";
import { OfferType } from "@common/types/offerTypes";
import { RhFullScreenDialog } from "@design-system/components/RhFullScreenDialog/RhFullScreenDialog";
import { Box, DialogContent, DialogTitle } from "@material-ui/core";
import { OfferForm } from "@pricing/components/OfferForm/OfferForm";
import React, { FC } from "react";

interface OfferDialogProps {
  campaign: CampaignType | null;
  offer?: OfferType;
  open: boolean;
  setClose: () => void;
  handleSuccess: (newOffer: OfferType) => void;
}

export const OfferDialog: FC<OfferDialogProps> = ({
  campaign,
  offer,
  open,
  setClose,
  handleSuccess,
}) => {
  const title = offer ? "Edit Offer" : "Create New Offer";

  return (
    <RhFullScreenDialog
      aria-labelledby="simple-dialog-title"
      fullScreen
      open={open}
      onClose={setClose}
    >
      <Box padding={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <OfferForm
            handleSuccess={handleSuccess}
            offer={offer}
            campaign={campaign}
          />
        </DialogContent>
      </Box>
    </RhFullScreenDialog>
  );
};
