import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { CampaignType } from "@common/types/campaignTypes";
import { RhApiError } from "@common/types/errorTypes";
import { OfferType } from "@common/types/offerTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { OfferDialog } from "@pricing/components/OfferDialog/OfferDialog";
import { useOffersPageStyles } from "@pricing/pages/OffersPage/OffersPage.style";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ID_FIELD_NAME = "id";
const OfferFieldNames: readonly (keyof OfferType)[] = [
  ID_FIELD_NAME,
  "title",
  "descriptionEn",
  "priority",
  "price",
  "termMonths",
  "startDate",
  "endDate",
] as const;

export const OffersPage: FC = () => {
  const { id } = useParams<{ id: IdType }>();
  const flash = useRhFlash();

  const classes = useOffersPageStyles();
  const headCells = OfferFieldNames.map((key) => ({ name: key }));

  const [
    { data, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<CampaignType>({} as CampaignType);

  const [newOffer, setNewOffer] = useState<OfferType>({} as OfferType);
  const [selectedOffer, setSelectedOffer] = useState<OfferType>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setPending();
    setDialogOpen(false);
    api.pricing.campaigns
      .retrieve(id)
      .then((campaignResponse: CampaignType) => {
        setSuccess(campaignResponse);
      })
      .catch((error: RhApiError) => {
        setFailure(error);

        flash.error("Error getting campaign. Please try again.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOffer]);

  const [hideExpired, setHideExpired] = useState<boolean>(true);

  const offers =
    data?.offers?.filter((offer) => {
      if (hideExpired) {
        return offer.active;
      }
      return true;
    }) ?? [];
  const campaign = data;

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  const handleEditOffer = (offer: OfferType) => (
    ev: React.MouseEvent<HTMLButtonElement>
  ): void => {
    ev.preventDefault();
    setSelectedOffer(offer);
    setDialogOpen(true);
  };

  const getStatus = (offer: OfferType) => {
    if (offer.active) {
      return "Active";
    } else {
      return <RhTypography color="error">Expired</RhTypography>;
    }
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12}>
        <RhFlexBox className={classes.header}>
          <RhTypography variant="h1">
            Offers for Campaign
            {id}
          </RhTypography>
          <RhButton
            disabled={requestMonitor.isPending || requestMonitor.isWaiting}
            onClick={(_event) => setDialogOpen(true)}
            color="primary"
            size="small"
            fullWidth={false}
            className={classes.addOfferBtn}
          >
            + Add Offer
          </RhButton>
        </RhFlexBox>
        <RhFlexBox className={classes.expired}>
          <RhTypography variant="body1">Hide Expired Offers</RhTypography>
          <RhToggle
            className={classes.expiredToggle}
            checked={hideExpired}
            onChange={() => {
              setHideExpired(!hideExpired);
            }}
          />
        </RhFlexBox>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          data-testid="offersPage__TableContainer"
          component={Paper}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <TableCell key={cell.name}>
                    <RhTypography variant="body2">{cell.name}</RhTypography>
                  </TableCell>
                ))}
                <TableCell>
                  <RhTypography variant="body2">Status</RhTypography>
                </TableCell>
                <TableCell>
                  <RhTypography variant="body2">Promo Code</RhTypography>
                </TableCell>
                <TableCell>
                  <RhTypography variant="body2">Promo Value</RhTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer: OfferType) => (
                <TableRow key={offer.id}>
                  {OfferFieldNames.map((field) => (
                    <TableCell key={`${field}-${offer.id}`}>
                      {offer[field]}
                    </TableCell>
                  ))}
                  <TableCell>{getStatus(offer)}</TableCell>
                  <TableCell key={`promo-code-${offer.id}`}>
                    {offer.promo?.code}
                  </TableCell>
                  <TableCell key={`promo-value-${offer.id}`}>
                    {offer.promo?.value
                      ? formatCurrency(offer.promo?.value)
                      : ""}
                  </TableCell>
                  <TableCell>
                    <RhButton
                      variant="contained"
                      color="inherit"
                      size="small"
                      onClick={handleEditOffer(offer)}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Edit
                      </Typography>
                    </RhButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <OfferDialog
          campaign={campaign}
          offer={selectedOffer}
          open={dialogOpen}
          setClose={() => {
            setDialogOpen(false);
            setSelectedOffer(undefined);
          }}
          handleSuccess={setNewOffer}
        />
      </Grid>
    </Grid>
  );
};
