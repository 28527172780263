import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhDialogTitleStyles = makeStyles<typeof rhythmTheme, "title">(
  (theme) =>
    createStyles({
      title: {
        padding: theme.spacing(4),
        textAlign: "center",
      },
    })
);
