import { api } from "@common/api/api";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { PlansResponseType } from "@common/types/planTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { PlansUploadDialog } from "@pricing/components/PlansUploadDialog/PlansUploadDialog";
import { usePlanPageStyles } from "@pricing/pages/PlansPage/PlansPage.style";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";

const ID_FIELD_NAME = "id";
const PlanFieldNames = [
  ID_FIELD_NAME,
  "Title",
  "Description",
  "Zuora Rate Plan Id",
  "Load Profile",
  "Product",
  "Utility",
  "Rec Product",
  "Bundled?",
  "$/MWh Termed COGS",
  "Annual Volume (MWh)",
  "TDSP Customer Charge ($)",
  "TDSP Per Unit ($/MWh)",
  "Term Months",
  "Term Volume (MWh)",
  "Status",
] as const;

export const PlansPage: FC = () => {
  const flash = useRhFlash();

  const classes = usePlanPageStyles();
  const headCells = PlanFieldNames.map((key) => ({ name: key }));

  const [
    { data, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<PlansResponseType>([]);

  useEffect(() => {
    setPending();

    api.pricing.plans
      .index()
      .then((planResponse: PlansResponseType) => {
        setSuccess(planResponse);
      })
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error("Error getting plans. Please try again.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const csvDownload = async () => {
    const blob = await api.pricing.plans.download();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;
    const fileName = `allRhythmPlans${dayjs().format(ISO8601_DATE_FORMAT)}.csv`;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const plans = data ?? [];

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  const archivePlan = (planId: IdType) => {
    setPending();
    api.pricing.plans
      .archive(planId)
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error("Error archiving plans. Please try again.");
      })
      .then(() => {
        return api.pricing.plans
          .index()
          .then((planResponse: PlansResponseType) => {
            setSuccess(planResponse);
          })
          .catch((error: RhApiError) => {
            setFailure(error);
            flash.error("Error getting plans. Please try again.");
          });
      });
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12}>
        <RhFlexBox>
          <RhTypography variant="h1">Plans</RhTypography>
          <RhButton
            onClick={() => csvDownload()}
            className={classes.plansBtn}
            color="primary"
            size="small"
            fullWidth={false}
          >
            Download Plans
          </RhButton>

          <PlansUploadDialog />
        </RhFlexBox>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          data-testid="plansPage__TableContainer"
          component={Paper}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <TableCell key={cell.name}>
                    <RhTypography variant="body2">{cell.name}</RhTypography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.id}</TableCell>
                  <TableCell>{plan.title}</TableCell>
                  <TableCell>{plan.descriptionEn}</TableCell>
                  <TableCell>{plan.zuoraRatePlanId}</TableCell>
                  <TableCell>{plan.loadProfile}</TableCell>
                  <TableCell>{plan.product}</TableCell>
                  <TableCell>{plan.utility.name}</TableCell>
                  <TableCell>{plan.recProduct}</TableCell>
                  <TableCell>{plan.bundled ? "Yes" : "No"}</TableCell>
                  <TableCell>{plan.termedCogsAmountMwh}</TableCell>
                  <TableCell>{plan.annualVolumeMwh}</TableCell>
                  <TableCell>{plan.tdspCustomerChargeAmount}</TableCell>
                  <TableCell>{plan.tdspPerUnitMwhAmount}</TableCell>
                  <TableCell>{plan.termMonths}</TableCell>
                  <TableCell>{plan.annualVolumeMwh}</TableCell>
                  <TableCell>
                    {plan.archived ? (
                      <RhTypography color="error">Archived</RhTypography>
                    ) : (
                      <RhButton
                        onClick={() => archivePlan(plan.id)}
                        color="default"
                        size="small"
                        fullWidth={false}
                      >
                        Archive Plan
                      </RhButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
