import { addressStates } from "@common/constants/addressStates.constant";
import {
  DATE_VALIDATION_REGEX,
  EMAIL_VALIDATION_REGEX,
  SSN_VALIDATION_REGEX,
  ZIPCODE_VALIDATION_REGEX,
} from "@common/constants/regex.constant";
import { unformatDateOfBirth } from "@common/forms/formatters";
import { unmaskPhone } from "@common/forms/phone.mask";
import { globalIntl } from "@common/i18n/globalIntl";
import dayjs from "dayjs";

export type FinalFormFieldValidator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>
> = (value: InputValueType, allValues: FormState) => string | undefined;

export type Validator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>
> = (
  value: InputValueType,
  allValues?: FormState,
  messageId?: string
) => string | undefined;

export type ValidationDictionary<FormState> = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FinalFormFieldValidator<any, FormState>[]
>;

export const isPositiveNumeric: Validator<string> = (value) => {
  if (!/^[.\d]+$/.test(value)) {
    return globalIntl.messages["validator.isNotAPositiveNumber"] as string;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired: Validator<any> = (
  value,
  _allValues,
  messageId = "validator.required"
) => {
  if (!value) {
    return globalIntl.messages[messageId] as string;
  }
};

export const isValidZipCode: Validator<string> = (value) => {
  if (!ZIPCODE_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidZipCode"] as string;
  }
};

export const isValidUSState: Validator<string> = (value) => {
  if (
    !addressStates.find(
      ({ abbreviation }) => abbreviation.toLowerCase() === value?.toLowerCase()
    )
  ) {
    return globalIntl.messages["validator.invalidUSState"] as string;
  }
};

export const isValidDateTodayOrFuture: Validator<string> = (value) => {
  const today = dayjs().startOf("day");

  if (!DATE_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidDateFormat"] as string;
  }
  if (dayjs(value).isBefore(today)) {
    return globalIntl.messages["validator.invalidDateValue"] as string;
  }
};

export const isValidAdultBirthday: Validator<string> = (value) => {
  const today = dayjs().startOf("day");
  const earliest18thBirthday = today.subtract(18, "year");

  const dob = unformatDateOfBirth(value);

  if (!DATE_VALIDATION_REGEX.test(dob)) {
    return globalIntl.messages[
      "validator.invalidBirthdayFieldDateFormat"
    ] as string;
  }
  if (dayjs(dob).isAfter(earliest18thBirthday)) {
    return globalIntl.messages["validator.invalidBirthday"] as string;
  }
};

export const isAfterDate = (valueDate: string, earliestDate: string) => {
  // Same day is acceptable
  const first = dayjs(earliestDate);
  const last = dayjs(valueDate);

  if (!earliestDate || !first.isValid()) {
    throw new Error("Comparison date is invalid");
  }
  if (!valueDate || !last.isValid()) {
    throw new Error("Date to check is invalid");
  }
  if (first.isAfter(last) && !first.isSame(last)) {
    return globalIntl.messages["validator.mustBeAfterStartDate"] as string;
  }
};

export const isValidPhoneNumber: Validator<string> = (value) => {
  if (unmaskPhone(value).length !== 10) {
    return globalIntl.messages["validator.invalidPhoneNumber"] as string;
  }
};

export const isValidEmail: Validator<string> = (value) => {
  if (!EMAIL_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidEmail"] as string;
  }
};

export const isValidConfirmationEmail = (
  value: string,
  comparisonValue: string
) => {
  if (value !== comparisonValue) {
    return globalIntl.messages["validator.invalidConfirmEmail"] as string;
  }
};

export const isValidOneOrBothRequired = (
  valueOne: string,
  valueTwo: string
) => {
  if (!valueOne && !valueTwo) {
    return "One or both values required.";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidUSAddress: ValidationDictionary<Record<string, any>> = {
  addressLine1: [isRequired],
  city: [isRequired],
  state: [isRequired, isValidUSState],
  zipCode: [isRequired, isValidZipCode],
};

export const isEqual = (
  value: string,
  comparisonValue: string,
  errorMessage: string
) => {
  if (value !== comparisonValue) {
    return errorMessage;
  }
};

export const isNotEqual = (
  value: string,
  comparisonValue: string,
  errorMessage: string
) => {
  if (value === comparisonValue) {
    return errorMessage;
  }
};

export const isValidSSN: Validator<string> = (value, formValues) => {
  if (formValues?.runCreditCheckType === "skipCheck") {
    return;
  }

  if (value && (!SSN_VALIDATION_REGEX.test(value) || value === "123456789")) {
    return globalIntl.messages["validator.invalidSSN"] as string;
  }
};

export const isAllCaps: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (value !== value.toUpperCase()) {
    return globalIntl.messages["validator.allCapsRequired"] as string;
  }
};
