import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useCampaignPageStyles = makeStyles<
  typeof rhythmTheme,
  | "root"
  | "table"
  | "tableHeadCell"
  | "addCampaignBtn"
  | "addCampaignHeader"
  | "welcomeMessage"
  | "titleContainer"
  | "header"
  | "expired"
  | "expiredToggle"
>((theme) =>
  createStyles({
    addCampaignBtn: {
      marginLeft: theme.spacing(2),
    },
    addCampaignHeader: {
      marginLeft: theme.spacing(2),
    },
    expired: {
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
      textAlign: "right",
    },
    expiredToggle: {
      marginLeft: theme.spacing(2),
    },
    header: {
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(4),
    },
    root: {
      margin: `${theme.spacing(3)}px auto`,
    },
    table: {
      "& td:first-child": {
        width: 150,
      },
      marginBottom: theme.spacing(4),
    },
    tableHeadCell: {
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    titleContainer: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
    welcomeMessage: {
      flex: 1,
      textAlign: "right",
    },
  })
);
