import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useMobileRhHeaderStyles = makeStyles<
  typeof rhythmTheme,
  { divideLastItem?: boolean },
  | "backdrop"
  | "iconWrapper"
  | "iconButton"
  | "listMobile"
  | "item"
  | "userGreetingContainer"
>((theme) =>
  createStyles({
    backdrop: {
      background: theme.palette.grey[700],
      cursor: "pointer",
    },
    iconButton: {
      "&:hover": {
        backgroundColor: "initial",
      },
    },
    iconWrapper: {
      left: theme.spacing(1),
      position: "absolute",
      top: theme.spacing(1),
      width: theme.spacing(RH_HEADER_HEIGHT),
    },
    item: {
      "& a": {
        // this increases the clickable area of links
        padding: `${theme.spacing(2)}px 240px ${theme.spacing(2)}px 0`,
      },
      "&:last-child": {
        fontSize: ({ divideLastItem }) => (divideLastItem ? 14 : undefined),
      },
      fontSize: 18,
      margin: `${theme.spacing(2)}px 0`,
      padding: `0 ${theme.spacing(2)}px`,
      whiteSpace: "nowrap",
    },
    listMobile: {
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexFlow: "column",
      flexGrow: 1,
      flexWrap: "nowrap",
      height: "100%",
      listStyle: "none",
      marginTop: theme.spacing(RH_HEADER_HEIGHT),
      paddingBottom: theme.spacing(RH_HEADER_HEIGHT),
      position: "fixed",
      width: "100%",
      zIndex: 1000,
    },
    userGreetingContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      fontWeight: "bold",
      justifyContent: "flex-start",
    },
  })
);
