import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { calculateInputErrorPosition } from "@design-system/theme/helpers/calculateInputErrorPosition";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  createStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import React, { FC, useState } from "react";

export type SetAsyncOptionsType = (options?: RhSelectOption[]) => void;

export interface RhSelectOption {
  label: string;
  value?: string;
}

interface RhSelectBase extends SelectProps {
  label: string;
  error?: boolean;
  errorMessage?: string;
}

interface RhSelectWithOptions extends RhSelectBase {
  options: RhSelectOption[];
  asyncOptions?: never;
}

interface RhSelectWithAsyncOptions extends RhSelectBase {
  options?: never;
  asyncOptions: (setOptions: SetAsyncOptionsType) => void;
}
type RhSelectProps = RhSelectWithOptions | RhSelectWithAsyncOptions;

const renderOptions = (
  options: RhSelectOption[],
  classes: ClassNameMap<"gutters">,
  isMobile: boolean
) => {
  return options.map((option: RhSelectOption) => (
    <MenuItem
      // "option" breaks safari and "li" breaks mobile
      component={isMobile ? "option" : "li"}
      classes={{ gutters: classes.gutters }}
      key={option.label}
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ));
};

export const RhSelect: FC<RhSelectProps> = ({
  label: inputLabel,
  options,
  asyncOptions,
  error,
  errorMessage,
  ...input
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [displayOptions, setDisplayOptions] = useState<
    RhSelectOption[] | undefined
  >(options);
  const { name, value } = input;

  const classes = useStyles();
  const classOverrides = {
    select: classes.select,
    root: "",
  };

  const setAsyncOptions = (selectOptions?: RhSelectOption[]) => {
    setDisplayOptions(selectOptions);
  };

  const fetchAsyncOptions = () => {
    if (!asyncOptions) {
      return;
    }

    asyncOptions(setAsyncOptions);
  };

  if (!value) {
    classOverrides.root = classes.root;
  }

  return (
    <FormControl error={error} className={classes.container}>
      <InputLabel shrink htmlFor={name}>
        {inputLabel}
      </InputLabel>
      <Select
        native={isMobile}
        {...input}
        inputProps={{ id: name }}
        displayEmpty
        classes={classOverrides}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          getContentAnchorEl: null,
        }}
        onOpen={() => {
          if (asyncOptions && !displayOptions) {
            fetchAsyncOptions();
          }
        }}
      >
        <MenuItem
          component={isMobile ? "option" : "li"}
          className={classes.placeholder}
          value=""
        >
          {inputLabel}
        </MenuItem>
        {displayOptions ? (
          renderOptions(displayOptions, classes, isMobile)
        ) : (
          <RhCircularProgress />
        )}
      </Select>
      <Box
        position="absolute"
        left={0}
        bottom={calculateInputErrorPosition(theme)}
      >
        <RhTypography variant="caption" color="error" fontWeight="Regular">
          {error ? errorMessage : ""}
        </RhTypography>
      </Box>
    </FormControl>
  );
};

const useStyles = makeStyles<
  typeof rhythmTheme,
  "container" | "gutters" | "menuPaper" | "placeholder" | "root" | "select"
>((theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    gutters: {
      paddingLeft: 16,
    },
    menuPaper: {
      maxHeight: 200,
    },
    placeholder: {
      display: "none",
    },
    root: {
      color: theme.palette.grey["200"],
    },

    select: {
      lineHeight: "20px",
    },
  })
);
