import { connectApi } from "@common/services/ajax";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import DayjsUtils from "@date-io/dayjs";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Security } from "@okta/okta-react";
import { App } from "@pricing/App";
import { signInPath } from "@pricing/routes/routePaths";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  OKTA_CONFIG,
  SENTRY_CONFIG,
} from "@pricing/settings/config";
import { generateTranslations } from "@pricing/utils/generateTranslations.util";
import { RewriteFrames } from "@sentry/integrations";
import {
  ErrorBoundary,
  reactRouterV5Instrumentation,
  init as sentryInit,
} from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history"; // eslint-disable-line import/no-extraneous-dependencies
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

connectApi({
  baseURL: AXIOS_BASE_URL,
  timeout: AXIOS_TIMEOUT_MS,
});
const history = createBrowserHistory();

if (SENTRY_CONFIG.dsn) {
  sentryInit({
    dsn: SENTRY_CONFIG.dsn,
    tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
    environment: getEnv(),
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
      new RewriteFrames(),
    ],
    release: SENTRY_CONFIG.release,
  });
}

// Even if we don't need translations for pricing, form validators do need to them to translate form errors.
// For pricing, we just load the common translations so the validator can return the english sentence
generateTranslations();

ReactDOM.render(
  <ErrorBoundary>
    <MuiThemeProvider theme={rhythmTheme}>
      <CssBaseline />
      <Router history={history}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <RhFlashProvider>
            <Security
              {...OKTA_CONFIG}
              onAuthRequired={() => {
                history.push(signInPath());
              }}
            >
              <App />
            </Security>
          </RhFlashProvider>
        </MuiPickersUtilsProvider>
      </Router>
    </MuiThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
