import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhFlashProviderStyles = makeStyles<
  typeof rhythmTheme,
  "error" | "success"
>((theme) =>
  createStyles({
    error: {
      "& #notistack-snackbar": {
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      },
      backgroundColor: `${theme.palette.error[500]} !important`, // mui uses important... so I had to do this... :facepalm:
      fontSize: 16,
    },
    success: {
      "& #notistack-snackbar": {
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      },
      backgroundColor: `${theme.palette.success[500]} !important`, // mui uses important... so I had to do this... :facepalm:
      fontSize: 16,
    },
  })
);
