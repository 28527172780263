import { useOktaAuth } from "@okta/okta-react";
import { campaignsPath } from "@pricing/routes/routePaths";
import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export const LoggedOutRoute: FC<RouteProps> = (props) => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  if (isAuthenticated) {
    return <Redirect to={campaignsPath()} />;
  } else {
    return <Route {...props} />;
  }
};
