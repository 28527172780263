import { getErrorMessage } from "@design-system/components/helpers/finalForm";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { InputProps as IInputProps } from "@material-ui/core/Input/Input";
import { Property } from "csstype";
import { FieldValidator } from "final-form";
import React, { FC } from "react";
import { FieldMetaState, useField } from "react-final-form";

export interface InputStyles {
  textAlign?: Property.TextAlign;
  paddingRight?: Property.PaddingRight;
}

const getHasErrors = (meta: FieldMetaState<string>) => {
  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  return !meta.active && meta.touched && hasValidationError;
};

interface RhTextFieldProps<Type> {
  autoComplete?: string;
  autoFocus?: boolean;
  format?: (value: Type, name: string) => unknown;
  parse?: (value: Type, name: string) => string;
  name: string;
  placeholder?: string;
  type?: "text" | "email" | "date" | "tel" | "password" | "number";
  validate?: FieldValidator<Type>;
  disabled?: boolean;
  styles?: InputStyles;
  inputMode?: "text" | "numeric" | "email";
  readOnly?: boolean;
  InputProps?: IInputProps;
}

// See our notes on using Final Form here:
// https://www.notion.so/gotrhythm/Forms-2247211da478413e9552b38f150484b1

export const RhTextField: FC<RhTextFieldProps<string>> = ({
  autoComplete = "off",
  autoFocus,
  format,
  parse,
  name,
  placeholder,
  type,
  validate,
  disabled,
  styles,
  readOnly,
  inputMode = "text",
  InputProps,
  children,
}) => {
  const { input, meta } = useField<string>(name, {
    format,
    validate,
    type,
    parse,
  });

  const hasErrors = getHasErrors(meta);
  const errorMessage = hasErrors ? getErrorMessage(meta) : undefined;

  return (
    <RhTextInput
      required
      {...input}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      placeholder={placeholder || ""}
      disabled={disabled || meta.submitting}
      error={hasErrors}
      readOnly={readOnly}
      inputProps={{
        style: {
          ...styles,
        },
        inputMode,
      }}
      errorMessage={errorMessage}
      {...InputProps}
    >
      {children}
    </RhTextInput>
  );
};
