import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { AuthClient } from "@common/services/AuthClient.service";
import { RhApiError } from "@common/types/errorTypes";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, Grid, GridProps } from "@material-ui/core";
import { useSignInPageStyles } from "@pricing/pages/SignInPage/SignInPage.style";
import { OKTA_CONFIG } from "@pricing/settings/config";
import React, { FC } from "react";
import { Form } from "react-final-form";

export interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues = Object.freeze<SignInFormValues>({
  email: "",
  password: "",
});

const signInFormValidator = generateValidationErrorCollector<SignInFormValues>({
  email: [isRequired, isValidEmail],
});

const gridContainerProps: GridProps = {
  container: true,
  direction: "column",
  wrap: "nowrap",
  spacing: 4,
};

const authClient = new AuthClient(OKTA_CONFIG);

const ERROR_LOGGING_IN = "Error logging in";

export const SignInPage: FC = () => {
  const classes = useSignInPageStyles();
  const flash = useRhFlash();

  const onSubmit = ({ email, password }: SignInFormValues) => {
    return authClient.signIn(email, password).catch((error: RhApiError) => ({
      email: [ERROR_LOGGING_IN],
      password: [ERROR_LOGGING_IN],
    }));
  };

  return (
    <div className={classes.root}>
      <Box marginBottom={10} textAlign="center">
        <RhTypography variant="h1">Rhythm Pricing</RhTypography>
      </Box>
      <Form<SignInFormValues>
        onSubmit={onSubmit}
        initialValues={{ ...initialValues }}
        validate={signInFormValidator}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} data-testid="signInForm" noValidate>
            <Grid {...gridContainerProps}>
              <Grid item xs={12}>
                <RhTextField name="email" autoComplete="email" autoFocus>
                  Email
                </RhTextField>
              </Grid>
              <Grid item xs={12}>
                <RhTextField name="password" type="password">
                  Password
                </RhTextField>
              </Grid>
              <Grid item xs={12}>
                <RhSubmitButton
                  onError={(errors) => {
                    flash.error(errors);
                  }}
                >
                  Login
                </RhSubmitButton>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
};
