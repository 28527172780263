import { ReactComponent as RhythmLogo } from "@common/images/rhythmLogoDarkPurple.svg";
import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { useOktaAuth } from "@okta/okta-react";
import {
  campaignPromosPath,
  campaignsPath,
  logoutPath,
  plansPath,
  signInPath,
} from "@pricing/routes/routePaths";
import React from "react";
import { Link, NavLink } from "react-router-dom";

export const PricingHeader = () => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  let items = [
    <NavLink key={campaignsPath()} to={campaignsPath()}>
      Campaigns
    </NavLink>,
    <NavLink key={campaignPromosPath()} to={campaignPromosPath()}>
      Campaign Promos
    </NavLink>,
    <NavLink key={plansPath()} to={plansPath()}>
      Plans
    </NavLink>,
    <NavLink key={logoutPath()} to={logoutPath()}>
      Log Out
    </NavLink>,
  ];

  if (!isAuthenticated) {
    items = [
      <NavLink key={signInPath()} to={signInPath()}>
        Log in
      </NavLink>,
    ];
  }

  const logo = (
    <Link to={isAuthenticated ? campaignsPath() : signInPath()}>
      <RhythmLogo />
    </Link>
  );

  return <RhHeader items={items} logo={logo} />;
};
