import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import dayjs from "dayjs";

export const formatPhoneNumber = (digits: string): string => {
  const phoneString: string =
    digits?.length === 11 ? digits?.substr(1) : digits;
  const areaCode = phoneString?.substr(0, 3);
  const prefix = phoneString?.substr(3, 3);
  const suffix = phoneString?.substr(6, 4);

  return `(${areaCode}) ${prefix}-${suffix}`;
};
export const formatDollarsToCents = (
  priceInDollars: string | number | null,
  includeSymbol: boolean | undefined = true,
  fractionDigits = 1,
  nanValue = "-"
): string => {
  const parsedPrice = parseFloat((priceInDollars as string) ?? "0");

  if (!Number.isFinite(parsedPrice)) {
    return nanValue;
  }
  const cents = (parsedPrice * 100).toFixed(fractionDigits);

  return includeSymbol ? `${cents}¢` : cents;
};

export const formatCurrency = (
  value: number,
  fractionDigits: 0 | 2 = 2,
  nanValue = "-"
): string => {
  if (!Number.isFinite(value)) {
    return nanValue;
  }
  const currencySymbol = value < 0 ? "-$" : "$";
  const finalValue = Math.abs(value).toFixed(fractionDigits);

  return `${currencySymbol}${finalValue}`;
};

export const formatRhythmCharge = (value: number): string => {
  const digits = value.toFixed(3);

  return `$${digits}`;
};

export const formatKwh = (value: number, fractionDigits: 0 | 2 = 2): string => {
  const finalValue = value.toFixed(fractionDigits);

  return `${finalValue} ${KILOWATT_HOURS_ABBREV}`;
};

export const fromIso8601toShorthandMonthPlusDay = (date: string) => {
  return dayjs(date).format("MMM D");
};

export const formatMonthDayYearDate = (date: string) =>
  dayjs(date).format("MM/DD/YYYY");

export const separateNumber = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
