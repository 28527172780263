import { useRhDialogTitleStyles } from "@design-system/components/RhDialogTitle/RhDialogTitle.style";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  DialogTitle,
  DialogTitleProps,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FC } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RhDialogTitleProps extends DialogTitleProps {}

export const RhDialogTitle: FC<RhDialogTitleProps> = ({
  children,
  className,
}) => {
  const classes = useRhDialogTitleStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dialogTitleSize = isMobile ? "h4" : "h2";

  return (
    <DialogTitle className={`${className} ${classes.title}`}>
      <RhTypography component="div" variant={dialogTitleSize}>
        {children}
      </RhTypography>
    </DialogTitle>
  );
};
