import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import React, { FC } from "react";
import { useFormState } from "react-final-form";

interface PriceDerivedFieldProps {
  name: string;
  placeholder: string;
  calculation: (price: number) => number | string;
}

export const PriceDerivedField: FC<PriceDerivedFieldProps> = ({
  calculation,
  name,
  placeholder,
  children,
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const value = values.price ? calculation(values.price).toString() : "";

  return (
    <RhTextInput
      name={name}
      placeholder={placeholder}
      readOnly
      disabled
      inputProps={{ value }}
    >
      {children}
    </RhTextInput>
  );
};
