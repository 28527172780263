import { Button, ButtonProps } from "@material-ui/core";
import React, { FC } from "react";

export type RhButtonProps = Omit<ButtonProps, "disableElevation">;

const defaultProps = Object.freeze<RhButtonProps>({
  color: "default",
  size: "medium",
  fullWidth: false,
  variant: "contained",
});

export const RhButton: FC<RhButtonProps> = (props) => {
  return <Button {...defaultProps} {...props} disableElevation />;
};
