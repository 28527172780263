import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { MobileRhHeader } from "@design-system/components/RhHeader/MobileRhHeader/MobileRhHeader";
import { useRhHeaderStyles } from "@design-system/components/RhHeader/RhHeader.style";
import { AppBar, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";

export interface RhHeaderProps {
  items: JSX.Element[];
  logo: JSX.Element;
  divideLastItem?: boolean;
}

export const name = () => {
  return <svg />;
};

export const RhHeader: FC<RhHeaderProps> = ({
  items,
  logo,
  divideLastItem,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useRhHeaderStyles();

  const listItems = (
    <ul data-testid="RhHeader__list" className={classes.listContainer}>
      {items.map((item, index) => {
        if (!item.key) {
          throw new Error(
            "Each list element passed in the props must have a key"
          );
        }

        // the item prior to the divider has a smaller marginRight
        const itemPriorToDivider = divideLastItem && index === items.length - 2;

        return (
          <React.Fragment key={item.key}>
            {divideLastItem && index === items.length - 1 && (
              <li className={classes.divider}>
                <RhDivider light orientation="vertical" />
              </li>
            )}
            <li
              className={classNames([
                classes.item,
                { RhHeader__DividerItem: itemPriorToDivider },
              ])}
            >
              {item}
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      component="nav"
      role="navigation"
      className={classes.container}
    >
      <div className={classes.logoContainer} data-testid="RhHeader__Logo">
        {logo}
      </div>

      {isMobile ? (
        <MobileRhHeader items={items} divideLastItem={divideLastItem} />
      ) : (
        // For some reason react complains about this, even if list items is a ul element. Adding the fragment worked but unsure why...
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{listItems}</>
      )}
    </AppBar>
  );
};
