import { Button } from "@material-ui/core";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

export interface RhLinkButtonProps
  extends Omit<
    React.HTMLProps<HTMLAnchorElement>,
    "color" | "component" | "rel" | "ref" | "size" | "href"
  > {
  external?: boolean;
  href: string;
  variant?: "outlined" | "contained" | "text";
  color?: "default" | "primary";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  disabled?: boolean;
}

export const RhLinkButton: FC<RhLinkButtonProps> = ({
  href,
  external,
  target,
  size = "small",
  variant = "contained",
  color,
  fullWidth = true,
  className,
  disabled,
  children,
}) => {
  const defaultTarget = external ? "_blank" : "_self";

  return (
    <Button
      component={external ? "a" : RouterLink}
      variant={variant}
      className={className}
      role="button"
      color={color}
      size={size}
      fullWidth={fullWidth}
      href={external ? href : undefined}
      to={external ? undefined : href}
      rel="nofollow noopener"
      target={target ?? defaultTarget}
      disabled={disabled}
      disableElevation
    >
      {children}
    </Button>
  );
};
