import { IdType } from "@common/types/apiTypes";

export enum PriceMethodTypes {
  Fixed = "FIXED",
  Margin = "MARGIN",
}

export interface PromoType {
  code: string;
  value: number;
}

export interface OfferType {
  descriptionEn: string;
  descriptionEs: string;
  longDescriptionEn: string;
  longDescriptionEs: string;
  price: string;
  id: IdType;
  planId: IdType;
  campaignId: IdType;
  termMonths: number;
  title: string;
  priority?: string;
  zuoraRatePlanId: IdType;
  startDate: string;
  endDate?: string;
  tdspMonthlyCharge: string;
  tdspKwhRate: string;
  rhythmKwhRate: string;
  grossMargin: number | null;
  pricePerMwh: number | null;
  priceMethod: PriceMethodTypes;
  promo?: PromoType;
  active: boolean;
}

export type OfferUpdatableType = Partial<
  Omit<
    OfferType,
    "id" | "price" | "tdspKwhRate" | "tdspMonthlyCharge" | "rhythmKwhRate" // read only fields (see OfferSerializer in pricing/serializers.py)
  >
>;

export type OfferRatesAndCharges = Pick<
  OfferType,
  "tdspMonthlyCharge" | "tdspKwhRate" | "rhythmKwhRate"
>;

export interface OfferResponseType {
  offers: OfferType[];
}
