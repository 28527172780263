import { makeStyles as muiMakeStyles } from "@material-ui/core/styles";
import { DefaultTheme } from "@material-ui/styles";
import {
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from "@material-ui/styles/withStyles";
import { Omit } from "@material-ui/types";

/**
 * Custom makeStyles to ensure { index: 1 }
 * Mui classes are being injected in <head> after these style only in production.
 * These styles should take precedence over mui styles
 * Seems to be a known issue
 * https://github.com/mui-org/material-ui/issues/18919
 *
 * @param stylesOrCreator
 * @param options
 */
export function rhMakeStyles<
  Theme = DefaultTheme,
  ClassKey extends string = string
>(
  // This is copied from makeStyles function type definition
  // eslint-disable-next-line @typescript-eslint/ban-types
  style: Styles<Theme, {}, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, "withTheme">
): (props?: unknown) => ClassNameMap<ClassKey>;

// TS function overloading
// eslint-disable-next-line no-redeclare
export function rhMakeStyles<
  Theme = DefaultTheme,
  // This is copied from makeStyles function type definition
  // eslint-disable-next-line @typescript-eslint/ban-types
  Props extends {} = {},
  ClassKey extends string = string
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, "withTheme">
): (props: Props) => ClassNameMap<ClassKey>;

// TS function overloading
// eslint-disable-next-line no-redeclare
export function rhMakeStyles(
  stylesOrCreator: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  options?: Record<string, unknown>
) {
  let muiOptions = {};

  if (options) {
    muiOptions = options;
  }
  return muiMakeStyles(stylesOrCreator, { ...muiOptions, index: 1 });
}
