import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { RhApiError } from "@common/types/errorTypes";

export type IdType = string;
export type EpochDateType = number;
export type ZuoraIdType = string;

export type SimpleURLMaker = () => string;
export type ParamURLMaker<Params = string> = (params: Params) => string;

export const AjaxStatuses = {
  Idle: "idle",
  Pending: "pending",
  Success: "success",
  Failure: "failure",
} as const;

export type AjaxStatus =
  | typeof AjaxStatuses.Idle
  | typeof AjaxStatuses.Pending
  | typeof AjaxStatuses.Success
  | typeof AjaxStatuses.Failure;

export interface UseAjaxStateType<ResponseType> {
  requestMonitor: RequestMonitor;
  data: ResponseType | null;
  error: RhApiError | null;
}

export interface UseAjaxSetters<ResponseType> {
  setSuccess: (data: ResponseType) => void;
  setFailure: (error: RhApiError) => void;
  setPending: () => void;
}
