import {
  RhSelect,
  RhSelectOption,
  SetAsyncOptionsType,
} from "@design-system/components/RhSelect/RhSelect";
import { SelectProps } from "@material-ui/core";
import React, { FC } from "react";
import { useField } from "react-final-form";

interface RhSelectFieldBase {
  name: string;
  label: string;
  selectProps?: SelectProps;
}

interface RhSelectFieldWithOptions extends RhSelectFieldBase {
  options: RhSelectOption[];
  asyncOptions?: never;
}

interface RhSelectFieldWithAsyncOptions extends RhSelectFieldBase {
  options?: never;
  asyncOptions: (setOptions: SetAsyncOptionsType) => void;
}
type RhSelectFieldProps =
  | RhSelectFieldWithOptions
  | RhSelectFieldWithAsyncOptions;

export const RhSelectField: FC<RhSelectFieldProps> = ({
  label,
  name,
  options,
  asyncOptions,
  selectProps,
}) => {
  const { meta, input } = useField<string>(name);

  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const hasErrors = !meta.active && meta.touched && hasValidationError;

  if (options) {
    return (
      <RhSelect
        options={options}
        error={hasErrors}
        {...input}
        {...selectProps}
        label={label}
      />
    );
  } else if (asyncOptions) {
    return (
      <RhSelect
        asyncOptions={asyncOptions}
        error={hasErrors}
        {...input}
        {...selectProps}
        label={label}
      />
    );
  } else {
    return null;
  }
};
