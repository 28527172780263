import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useOffersPageStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "header" | "addOfferBtn" | "expired" | "expiredToggle"
>((theme) =>
  createStyles({
    addOfferBtn: {
      marginLeft: theme.spacing(2),
    },
    expired: {
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
      textAlign: "right",
    },
    expiredToggle: {
      marginLeft: theme.spacing(2),
    },
    header: {
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(4),
    },
    root: {
      margin: `${theme.spacing(3)} auto`,
    },
  })
);
