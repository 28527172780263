import { FieldMetaState } from "react-final-form";

export const getErrorMessage = ({
  error,
  submitError,
}: FieldMetaState<string>): string | undefined => {
  const e = error || submitError;

  return Array.isArray(e) ? e[0] : e;
};
