import {
  CreateCustomerRequest,
  createCustomerRequestSchema,
} from "@common/api/apiRequests";
import {
  billingFetchRSASignature,
  customerInvoiceHistory,
  downloadInvoiceJson,
  paymentMethodsDelete,
  paymentMethodsList,
  paymentMethodsRetrieve,
  paymentMethodsRetrieveDefault,
  paymentMethodsUpdate,
  uploadInvoiceJson,
} from "@common/api/billingUrls";
import {
  notificationChannelsList,
  notificationMessagesList,
} from "@common/api/customerUrls";
import { urls } from "@common/api/urls";
import { ajax } from "@common/services/ajax";
import { AccountSummaryType } from "@common/types/accountSummaryTypes";
import {
  AddACHResponseType,
  AveragedBillingMonthlyChargeResponseType,
  CreatePaymentResponseType,
  EnrollmentStatusUpdatedResponseType,
  EnrollmentStatusesResponseType,
  GetSetPasswordStateTokenResponseType,
  LoyaltyProgramRedemptionResponseType,
  MeterAvailabilityResponseType,
  NotificationChannelsResponseType,
  NotificationMessagesResponseType,
  OffersAvailabilityResponseType,
  OffersSearchResponseType,
  PlaidLinkTokenResponse,
  RSASignatureResponseType,
  RewardsResponseType,
  SetPasswordResponseType,
  TriggerEdiResponseType,
  ValidInboundEDIResponseType,
} from "@common/types/apiResponseTypes";
import { IdType, ZuoraIdType } from "@common/types/apiTypes";
import { CampaignType } from "@common/types/campaignTypes";
import { CreditScoreEvaluationType } from "@common/types/creditCheckTypes";
import {
  AddressFormType,
  ChannelSubscriptionStatus,
  CustomerListItemType,
  CustomerSearchResponseType,
  CustomerType,
  CustomerUpdatePostDataType,
  EDIMessagePostDataType,
  SaveACHRequestType,
  UpdateSubscriptionParams,
} from "@common/types/customerTypes";
import {
  EDIGeneratePostDataType,
  EDIMessageType,
} from "@common/types/ediTypes";
import { OfferType, OfferUpdatableType } from "@common/types/offerTypes";
import {
  CreditCardRetrieveType,
  GeneratedInvoiceResponseType,
  PaymentMethodRetrieveType,
  PaymentMethodsResponseType,
  PlaidPublicAccountTokenType,
  UpdatePaymentMethodType,
} from "@common/types/paymentMethodTypes";
import {
  PlanRequestType,
  PlanUploadResponseType,
  PlansResponseType,
  UtilitySearchRequestType,
  UtilitySearchResponseType,
} from "@common/types/planTypes";
import {
  ProspectCreatePostDataType,
  ProspectCreditCheckRequestType,
  ProspectTrackEnteredSignupPostDataType,
  ProspectTrackServiceUnavailablePostDataType,
  ProspectType,
} from "@common/types/prospectTypes";
import { LookupSuggestionType } from "@common/types/smartyStreetsTypes";
import { captureException, withScope } from "@sentry/react";
import { AxiosRequestConfig } from "axios";

const PLANS_UPLOAD_FILE_NAME = "Plan_menu.csv";

export const api = {
  billing: {
    fetchRSASignature: (): Promise<RSASignatureResponseType> => {
      return ajax.get(billingFetchRSASignature());
    },
    paymentMethods: {
      list: (
        config?: AxiosRequestConfig
      ): Promise<PaymentMethodsResponseType> => {
        return ajax.get(paymentMethodsList(), config);
      },
      retrieve: (id: ZuoraIdType): Promise<PaymentMethodRetrieveType> => {
        return ajax.get(paymentMethodsRetrieve(id));
      },
      update: (
        id: ZuoraIdType,
        paymentMethodData: UpdatePaymentMethodType
      ): Promise<CreditCardRetrieveType> => {
        return ajax.put(paymentMethodsUpdate(id), paymentMethodData);
      },
      delete: (id: ZuoraIdType): Promise<CreditCardRetrieveType> => {
        return ajax.destroy(paymentMethodsDelete(id));
      },
      default: (
        id: ZuoraIdType,
        paymentMethodData: UpdatePaymentMethodType
      ): Promise<CreditCardRetrieveType> => {
        return ajax.put(paymentMethodsRetrieveDefault(id), paymentMethodData);
      },
    },
    generatedInvoice: {
      listCustomerInvoices: (
        customerId: IdType
      ): Promise<GeneratedInvoiceResponseType> => {
        return ajax.get(customerInvoiceHistory(customerId));
      },
      upload: (
        id: IdType,
        jsonFile: File,
        backendFileIdentifier: string
      ): Promise<null> => {
        const formData = new FormData();

        formData.append(backendFileIdentifier, jsonFile, jsonFile.name);
        return ajax.postFile(uploadInvoiceJson(id), formData);
      },
      download: (id: IdType): Promise<File> => {
        return ajax.getFile(downloadInvoiceJson(id));
      },
    },
  },
  customers: {
    index: (): Promise<CustomerListItemType[]> => {
      return ajax.get(urls.customers.index());
    },
    create: (customerData: CreateCustomerRequest): Promise<CustomerType> => {
      try {
        createCustomerRequestSchema.validateSync(customerData);
      } catch (error) {
        withScope((scope) => {
          const errorData = {
            errorMessage: error.message,
            errorStack: error.stack,
            customerData: JSON.stringify(customerData, null, 2),
          };

          scope.setContext("Create customer request body malformed", errorData);
          captureException(error);
        });
      }

      return ajax.post<CustomerType, CreateCustomerRequest>(
        urls.customers.create(),
        customerData
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retrieve: (id: IdType, params?: any): Promise<CustomerType> => {
      return ajax.get(urls.customers.retrieve(id), { params });
    },
    me: (): Promise<CustomerType> => {
      return ajax.get<CustomerType>(urls.customers.me());
    },
    available: (email: string) => {
      return ajax.get(urls.customers.available(), { params: { email } });
    },
    update: (
      id: IdType,
      customerData: Partial<CustomerType> & Partial<AddressFormType>
    ): Promise<CustomerType> => {
      return ajax.patch<CustomerType, CustomerUpdatePostDataType>(
        urls.customers.update(id),
        customerData
      );
    },
    search: (query: string): Promise<CustomerSearchResponseType> => {
      return ajax.get<CustomerSearchResponseType>(urls.customers.search(query));
    },
    accountSummary: {
      retrieve: (id: IdType): Promise<AccountSummaryType> => {
        return ajax.get<AccountSummaryType>(
          urls.customers.accountSummary.retrieve(id)
        );
      },
      averagedBillingMonthlyCharge: (
        id: IdType
      ): Promise<AveragedBillingMonthlyChargeResponseType> => {
        return ajax.get<AveragedBillingMonthlyChargeResponseType>(
          urls.customers.accountSummary.averagedBillingMonthlyCharge(id)
        );
      },
    },
    unsubscribedChannelIds: (id: IdType): Promise<ChannelSubscriptionStatus> =>
      ajax.get<ChannelSubscriptionStatus>(
        urls.customers.unsubscribedChannelIds(id)
      ),
    updateSubscriptionStatus: (
      id: IdType,
      subscriptionParams: UpdateSubscriptionParams
    ): Promise<{ code: string }> =>
      ajax.patch<{ code: string }>(
        urls.customers.updateSubscriptionStatus(id),
        subscriptionParams
      ),
    rewards: (customerId: IdType): Promise<RewardsResponseType> => {
      return ajax.get(urls.customers.loyaltyProgram.retrieve(customerId));
    },
    loyaltyProgramRedeem: (
      id: IdType,
      amount: number
    ): Promise<LoyaltyProgramRedemptionResponseType> =>
      ajax.post<LoyaltyProgramRedemptionResponseType>(
        urls.customers.loyaltyProgram.redeem(id),
        {
          amount,
        }
      ),
    notificationChannels: {
      list: (): Promise<NotificationChannelsResponseType> => {
        return ajax.get(notificationChannelsList());
      },
    },
    notificationMessages: {
      list: (): Promise<NotificationMessagesResponseType> => {
        return ajax.get(notificationMessagesList());
      },
    },
    payments: {
      create: (
        customerId: IdType,
        paymentData: {
          amount: number;
          paymentMethodId: string;
        }
      ): Promise<CreatePaymentResponseType> => {
        return ajax.post(
          urls.customers.payments.create(customerId),
          paymentData
        );
      },
      saveACH: (
        id: IdType,
        request: SaveACHRequestType
      ): Promise<AddACHResponseType> => {
        return ajax.post(urls.customers.plaid.saveACH(id), request);
      },
    },
    ediMessages: {
      create: (
        id: IdType,
        messageData: EDIMessagePostDataType
      ): Promise<EDIMessageType> => {
        return ajax.post(urls.customers.ediMessages.create(id), messageData);
      },
    },
    password: {
      getStateToken: ({
        activationToken,
      }: {
        activationToken: string;
      }): Promise<GetSetPasswordStateTokenResponseType> => {
        const payload = { activationToken };

        return ajax.post(urls.customers.password.getStateToken(), payload);
      },
      set: ({
        activationToken,
        password,
        confirmPassword,
        stateToken,
      }: {
        activationToken?: string;
        password: string;
        confirmPassword: string;
        stateToken?: string;
      }): Promise<SetPasswordResponseType> => {
        const payload = {
          activationToken,
          password,
          confirmPassword,
          stateToken,
        };

        return ajax.post(urls.customers.password.set(), payload);
      },
      sendResetEmail: (email: string, referrer?: string): Promise<void> => {
        return ajax.post(urls.customers.password.sendResetEmail(), {
          email,
          referrer,
        });
      },
      reset: ({
        recoveryToken,
        password,
        confirmPassword,
      }: {
        recoveryToken: string;
        password: string;
        confirmPassword: string;
      }): Promise<SetPasswordResponseType> => {
        const payload = {
          recoveryToken,
          password,
          confirmPassword,
        };

        return ajax.post(urls.customers.password.reset(), payload);
      },
    },
    plaid: {
      getLinkToken: (id: IdType): Promise<PlaidLinkTokenResponse> => {
        return ajax.get(urls.customers.plaid.linkToken(id));
      },
    },
  },
  edi: {
    meterAvailability: (
      addressData: Partial<LookupSuggestionType>
    ): Promise<MeterAvailabilityResponseType> => {
      return ajax.get(urls.edi.meterAvailability(), {
        params: addressData,
      });
    },
    getEnrollmentStatuses: (): Promise<EnrollmentStatusesResponseType> => {
      return ajax.get(urls.edi.enrollmentStatuses());
    },
    setEnrollmentStatus: (
      id: IdType,
      status: string
    ): Promise<EnrollmentStatusUpdatedResponseType> => {
      return ajax.patch(urls.edi.setEnrollmentStatus(id), {
        enrollmentStatus: status,
      });
    },
  },
  ediProvider: {
    triggerEDIGeneration: (
      id: IdType,
      ediIntent: string
    ): Promise<TriggerEdiResponseType> => {
      return ajax.post<TriggerEdiResponseType, EDIGeneratePostDataType>(
        urls.ediProvider.triggerEdiGeneration(),
        {
          customerId: id,
          ediIntent,
        }
      );
    },
    getAllowableEDI: (id: IdType): Promise<ValidInboundEDIResponseType> => {
      return ajax.get(urls.ediProvider.getAllowableInboundEDIActions(id));
    },
  },
  prospects: {
    create: (
      prospectData: ProspectCreatePostDataType
    ): Promise<ProspectType> => {
      return ajax.post<ProspectType, ProspectCreatePostDataType>(
        urls.prospects.create(),
        prospectData
      );
    },
    retrieve: (prospectId: IdType): Promise<ProspectType> => {
      return ajax.get<ProspectType>(urls.prospects.retrieve(prospectId));
    },
    update: (
      prospectId: IdType,
      prospectData: ProspectCreatePostDataType
    ): Promise<null> => {
      return ajax.patch<null, ProspectCreatePostDataType>(
        urls.prospects.update(prospectId),
        prospectData
      );
    },
    creditScoreEvaluation: (
      customerData: ProspectCreditCheckRequestType
    ): Promise<CreditScoreEvaluationType> => {
      return ajax.post<CreditScoreEvaluationType, Partial<CustomerType>>(
        urls.prospects.creditScoreEvaluation(),
        customerData
      );
    },
    billingLinkToken: (
      prospectId: IdType,
      email: string
    ): Promise<PlaidLinkTokenResponse> => {
      return ajax.get<PlaidLinkTokenResponse>(
        urls.prospects.billingLinkToken(prospectId),
        { params: { email } }
      );
    },
    createAch: (
      plaidPublicAccountData: PlaidPublicAccountTokenType
    ): Promise<{ billingPaymentMethodId: string }> => {
      return ajax.post(urls.prospects.createAch(), plaidPublicAccountData);
    },
    trackEnteredSignup: (id: IdType | null, email: string): Promise<null> => {
      if (!id) {
        return Promise.resolve(null);
      }
      return ajax.post<null, ProspectTrackEnteredSignupPostDataType>(
        urls.prospects.trackEnteredSignup(id),
        { email }
      );
    },
    trackServiceUnavailable: (
      id: IdType | null,
      email: string
    ): Promise<null> => {
      if (!id) {
        return Promise.resolve(null);
      }
      return ajax.post<null, ProspectTrackServiceUnavailablePostDataType>(
        urls.prospects.trackServiceUnavailable(id),
        { email }
      );
    },
  },
  pricing: {
    offers: {
      search: (
        dunsNumber: string,
        campaignSlug: string | null
      ): Promise<OffersSearchResponseType> => {
        return ajax.get(urls.pricing.offers.search(), {
          params: { dunsNumber, campaignSlug },
        });
      },
      create: (newOffer: Partial<OfferType>): Promise<OfferType> =>
        ajax.post(urls.pricing.offers.create(), newOffer),
      update: (
        id: IdType,
        updatedOffer: OfferUpdatableType
      ): Promise<OfferType> =>
        ajax.patch<OfferType, OfferUpdatableType>(
          urls.pricing.offers.update(id),
          updatedOffer
        ),
      availability: (
        streetAddress: Partial<AddressFormType>
      ): Promise<OffersAvailabilityResponseType> => {
        return ajax.get(urls.pricing.offers.availability(), {
          params: { ...streetAddress },
        });
      },
      efl: (id: IdType): Promise<Blob> => {
        return ajax.getFile(urls.pricing.offers.efl(id));
      },
    },
    campaigns: {
      index: (): Promise<CampaignType[]> => {
        return ajax.get(urls.pricing.campaigns.index());
      },
      retrieve: (id: IdType): Promise<CampaignType> => {
        return ajax.get(urls.pricing.campaigns.retrieve(id));
      },
      create: (newCampaign: Partial<CampaignType>): Promise<CampaignType> =>
        ajax.post(urls.pricing.campaigns.create(), newCampaign),
    },
    plans: {
      index: (
        plansRequestType: PlanRequestType = {}
      ): Promise<PlansResponseType> => {
        return ajax.get(urls.pricing.plans.index(), {
          params: plansRequestType,
        });
      },
      download: (): Promise<Blob> => {
        return ajax.getFile(urls.pricing.plans.download());
      },
      upload: (csvFile: File): Promise<PlanUploadResponseType> => {
        const formData = new FormData();

        formData.append("plans_file", csvFile, PLANS_UPLOAD_FILE_NAME);
        return ajax.postFile(urls.pricing.plans.upload(), formData);
      },
      archive: (id: IdType): Promise<PlansResponseType> => {
        return ajax.post<PlansResponseType>(urls.pricing.plans.archive(id), {});
      },
    },
  },
  utilities: {
    search: (
      queryParams?: UtilitySearchRequestType
    ): Promise<UtilitySearchResponseType> => {
      return ajax.get(urls.utilities.search(), { params: queryParams ?? {} });
    },
  },
};
