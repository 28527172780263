export const RH_HEADER_HEIGHT = 7.5;
export const ANNOUNCEMENT_HEIGHT = 4;
export const PAGE_GUTTER = 16; // px
export const UNIT_OF_SPACING = 8;
export const INPUT_ACTIVE_UNDERLINE_HEIGHT = 2;
export const MAIN_CONTAINER_WIDTH = 1096;
export const PORTAL_TOP_MARGIN = 2;
export const LOGGED_OUT_TOP_MARGIN_MOBILE = 4;
export const LOGGED_OUT_TOP_MARGIN = 6;
export const FULL_SCREEN_DIALOG_CONTENT_MAX_WIDTH = 700;

export const OPS_CUSTOMER_DETAIL_BUTTON_WIDTH = 350;
export const OPS_CUSTOMER_DETAIL_DIALOG_WIDTH = 300;
export const DIALOG_BUTTON_WIDTH = 180;

// sign up flow
export const OFFER_CAROUSEL_WIDTH = 890;
export const LOGGED_OUT_FORM_WIDTH = 338;
export const LOGGED_OUT_PAGE_DENSE_WIDTH = 528;
