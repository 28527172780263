import { useRhRadioInputStyles } from "@design-system/components/RhRadioInput/RhRadioInput.style";
import { SelectedOption, UnselectedOption } from "@design-system/icons";
import { SvgIcon } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { RadioProps } from "@material-ui/core/Radio/Radio";
import React, { FC } from "react";

export type RhRadioInputProps = RadioProps;

export const RhRadioInput: FC<RhRadioInputProps> = ({
  value,
  ...restProps
}) => {
  const classes = useRhRadioInputStyles({});

  return (
    <Radio
      classes={{ root: classes.radioRoot }}
      color="primary"
      id={typeof value === "string" ? value : undefined}
      icon={
        <SvgIcon
          component={UnselectedOption}
          viewBox="0 0 18 18"
          fontSize="small"
          className={classes.icon}
        />
      }
      checkedIcon={
        <SvgIcon
          component={SelectedOption}
          viewBox="0 0 18 18"
          fontSize="small"
          className={classes.icon}
        />
      }
      value={value}
      {...restProps}
    />
  );
};
