import humps from "humps";

export const convertCamelCaseToSentenceCase = (text: string) =>
  humps
    .decamelize(text)
    .split("_")
    .reduce(
      (result, word) =>
        `${result} ${word.charAt(0).toUpperCase() + word.slice(1)}`,
      ""
    );
