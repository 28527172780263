import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";

// Docs:
// https://www.notion.so/7958d63be7bf494fa121fbaa57a5db34#00df437f037b4e3fb5cf3c4add9c6638

export class RequestMonitor {
  private readonly status: AjaxStatus;

  constructor(status: AjaxStatus = AjaxStatuses.Idle) {
    this.status = status;
  }

  get isWaiting(): boolean {
    return (
      this.status === AjaxStatuses.Idle || this.status === AjaxStatuses.Pending
    );
  }

  get isIdle(): boolean {
    return this.status === AjaxStatuses.Idle;
  }

  get isPending(): boolean {
    return this.status === AjaxStatuses.Pending;
  }

  get didFail(): boolean {
    return this.status === AjaxStatuses.Failure;
  }

  get didSucceed(): boolean {
    return this.status === AjaxStatuses.Success;
  }
}
