import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isAfterDate,
  isPositiveNumeric,
  isRequired,
} from "@common/forms/validators";
import { IdType } from "@common/types/apiTypes";
import { CampaignPromoType } from "@common/types/campaignTypes";
import { RhApiError } from "@common/types/errorTypes";
import {
  OfferType,
  OfferUpdatableType,
  PriceMethodTypes,
  PromoType,
} from "@common/types/offerTypes";
import { PlanType } from "@common/types/planTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhSelectField } from "@design-system/components/RhSelectField/RhSelectField";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DateField } from "@pricing/components/DateField/DateField";
import { GrossMarginField } from "@pricing/components/GrossMarginField/GrossMarginField";
import { PriceDerivedField } from "@pricing/components/PriceDerivedField/PriceDerivedField";
import { PriceField } from "@pricing/components/PriceField/PriceField";
import { PricingButton } from "@pricing/components/PricingButton/PricingButton";
import { OFFER_PROMO_CONSTRAINT_ERROR } from "@pricing/constants/offer.constant";
import {
  calculateEnergyRateKWH,
  calculateEnergyRateMWH,
  calculateGrossMargin,
  calculatePricePerMwh,
} from "@pricing/utils/calculations.util";
import React, { FC } from "react";
import { Form } from "react-final-form";

interface OfferFormValues {
  priority?: string;
  price: string;
  startDate: string;
  endDate: string;
  priceMethod: PriceMethodTypes;
  promo?: PromoType;
}

export const offerFormValidator = generateValidationErrorCollector<OfferFormValues>(
  {
    priority: [isPositiveNumeric],
    price: [isRequired, isPositiveNumeric],
    startDate: [isRequired],
    endDate: [
      (endDate: string, { startDate }: OfferFormValues) => {
        if (endDate) {
          return isAfterDate(endDate, startDate);
        }
      },
    ],
  }
);

interface OfferFormProps {
  campaignId: IdType;
  campaignPromo?: CampaignPromoType;
  offer?: OfferType;
  plan: PlanType;
  handleSuccess: (newOffer: OfferType) => void;
}

export const OfferDetailsForm: FC<OfferFormProps> = ({
  campaignId,
  campaignPromo,
  offer,
  plan,
  handleSuccess,
}) => {
  const flash = useRhFlash();

  const onSubmit = ({
    price,
    startDate,
    endDate,
    priceMethod,
    promo,
    priority,
  }: OfferFormValues) => {
    const baseParams: OfferUpdatableType = {
      startDate,
      endDate,
      planId: plan.id,
      termMonths: plan.termMonths,
      zuoraRatePlanId: plan.zuoraRatePlanId,
      priceMethod,
      campaignId,
      promo,
      priority,
    };

    if (priceMethod === PriceMethodTypes.Fixed) {
      baseParams.pricePerMwh = calculateEnergyRateMWH(parseFloat(price), plan);
    } else if (priceMethod === PriceMethodTypes.Margin) {
      baseParams.grossMargin = calculateGrossMargin(parseFloat(price), plan);
    }

    const request = offer
      ? api.pricing.offers.update(offer.id, baseParams)
      : api.pricing.offers.create(baseParams);

    request
      .then((response) => {
        handleSuccess(response as OfferType);
      })
      .catch(({ data }: RhApiError) => {
        const errorMsg =
          data.errorCode === OFFER_PROMO_CONSTRAINT_ERROR
            ? "Offer promo not allowed on a campaign that has a promo"
            : "Error saving offer. Please try again.";

        flash.error(errorMsg);
      });
  };

  const initialValues = offer ?? {
    priority: undefined,
    priceMethod: PriceMethodTypes.Fixed,
    price: undefined,
    startDate: undefined,
    endDate: undefined,
  };

  return (
    <Form<OfferFormValues>
      onSubmit={onSubmit}
      validate={offerFormValidator}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box display="flex" marginBottom={2} marginTop={2}>
            <Table size="small" padding="checkbox">
              <TableHead>
                <TableRow>
                  <TableCell>TDSP Customer Charge</TableCell>
                  <TableCell>TDSP Per Unit</TableCell>
                  <TableCell>$/MWH Termed COGS</TableCell>
                  <TableCell>Term Volume</TableCell>
                  <TableCell>Annual Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="choosePlanForm__tableBody">
                <TableRow key={plan.id}>
                  <TableCell>{plan.tdspCustomerChargeAmount}</TableCell>
                  <TableCell>{plan.tdspPerUnitMwhAmount}</TableCell>
                  <TableCell>{plan.termedCogsAmountMwh}</TableCell>
                  <TableCell>{plan.termVolumeMwh}</TableCell>
                  <TableCell>{plan.annualVolumeMwh}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" marginBottom={2} marginTop={2}>
            <Box padding={2} flex={1}>
              <RhTextField placeholder="Priority" name="priority">
                Offer Priority
              </RhTextField>
            </Box>
            <Box padding={2} flex={1} data-testid="priceMethod__select">
              <RhSelectField
                name="priceMethod"
                label="Price Method"
                options={[
                  { label: "Fixed Price", value: PriceMethodTypes.Fixed },
                  { label: "Margin Price", value: PriceMethodTypes.Margin },
                ]}
              />
            </Box>
            {!campaignPromo && (
              <Box padding={2} flex={1}>
                <RhTextField name="promo.code">Promo Code</RhTextField>
                {offer?.promo?.value && (
                  <Box paddingX={2} paddingTop={1}>
                    Value: {formatCurrency(offer?.promo?.value)}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box display="flex" marginBottom={2}>
            <Box padding={2} flex={1}>
              <PriceField
                name="price"
                plan={plan}
                placeholder="Price @ 2,000 KWH"
              >
                Price @ 2,000 KWH
              </PriceField>
            </Box>
            <Box padding={2} flex={1}>
              <PriceDerivedField
                calculation={(price) => calculatePricePerMwh(price, plan, 1000)}
                name="price1000"
                placeholder="Price @ 1,000 KWH"
              >
                Price @ 1,000 KWH
              </PriceDerivedField>
            </Box>
            <Box padding={2} flex={1}>
              <PriceDerivedField
                placeholder="Price @ 500 KWH"
                calculation={(price) => calculatePricePerMwh(price, plan, 500)}
                name="price500"
              >
                Price @ 500 KWH
              </PriceDerivedField>
            </Box>
          </Box>
          <Box display="flex" marginBottom={2}>
            <Box padding={2} flex={1}>
              <GrossMarginField
                name="grossMargin"
                plan={plan}
                placeholder="Gross margin ($/MWH)"
              >
                Gross margin ($/MWH)
              </GrossMarginField>
            </Box>
            <Box padding={2} flex={1}>
              <PriceDerivedField
                calculation={(price: number) =>
                  calculateEnergyRateKWH(price, plan)
                }
                name="energyRate"
                placeholder="Energy rate"
              >
                Energy rate
              </PriceDerivedField>
            </Box>
          </Box>
          <Box display="flex" marginBottom={2}>
            <Box padding={2} flex={1}>
              <DateField name="startDate" label="Start" />
            </Box>
            <Box padding={2} flex={1}>
              <DateField name="endDate" label="End" />
            </Box>
          </Box>
          <Box display="flex" marginBottom={2}>
            <Box padding={2} flex={1}>
              <PricingButton type="submit" disabled={plan.archived === true}>
                Save Offer
              </PricingButton>
            </Box>
            <Box padding={2} flex={1}>
              {plan.archived ? (
                <RhTypography variant="body1" color="error">
                  Plan is no longer valid, please select a new one.
                </RhTypography>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </form>
      )}
    />
  );
};
