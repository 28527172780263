import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { DialogContent, DialogContentProps } from "@material-ui/core";
import React, { FC } from "react";

import { useRhDialogContentWithButtonsStyles } from "./RhDialogContentWithButtons.style";

interface ButtonProps {
  onClick(): void;
  label: string;
  isDisabled?: boolean;
}

interface RhDialogContentWithButtonsProps extends DialogContentProps {
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
}

export const RhDialogContentWithButtons: FC<RhDialogContentWithButtonsProps> = ({
  children,
  primaryButton,
  secondaryButton,
  ...restProps
}) => {
  const classes = useRhDialogContentWithButtonsStyles();

  return (
    <DialogContent {...restProps}>
      {children}
      <RhFlexBox
        justifyContent="space-between"
        marginY={3}
        className={classes.buttonsContainter}
      >
        <RhButton
          data-testid="RhDialogContentWithButtons__Button--secondary"
          className={classes.button}
          onClick={secondaryButton.onClick}
          variant="outlined"
        >
          {secondaryButton.label}
        </RhButton>
        <RhButton
          data-testid="RhDialogContentWithButtons__Button--primary"
          className={classes.button}
          color="primary"
          onClick={primaryButton.onClick}
          type="submit"
          disabled={primaryButton.isDisabled}
        >
          {primaryButton.label}
        </RhButton>
      </RhFlexBox>
    </DialogContent>
  );
};
