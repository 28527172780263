import { RhButton } from "@design-system/components/RhButton/RhButton";
import { ButtonProps } from "@material-ui/core";
import { SubmissionErrors, ValidationErrors } from "final-form";
import isEmpty from "lodash/isEmpty";
import React, { FC, MouseEventHandler, useEffect } from "react";
import { useForm, useFormState } from "react-final-form";

export interface RhSubmitButtonProps
  extends Pick<ButtonProps, "fullWidth" | "size"> {
  disabled?: boolean;
  disableOnInvalid?: boolean;
  disallowPristineSubmit?: boolean;
  onError?(submitErrors: SubmissionErrors | ValidationErrors): void;
  restartOnSuccess?: boolean;
}

// See our notes on using Final Form here:
// https://www.notion.so/gotrhythm/2247211da478413e9552b38f150484b1

const hasErrors = (errs: SubmissionErrors | ValidationErrors) => {
  return errs && !isEmpty(errs);
};

export const RhSubmitButton: FC<RhSubmitButtonProps> = ({
  children,
  onError,
  ...props
}) => {
  const formState = useFormState({
    subscription: {
      invalid: true,
      submitting: true,
      errors: true,
      submitErrors: true,
      pristine: true,
      submitSucceeded: true,
      hasValidationErrors: true,
      hasSubmitErrors: true,
      modifiedSinceLastSubmit: true,
    },
  });

  const formApi = useForm();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (_event) => {
    if (onError && hasErrors(formState.errors)) {
      onError(formState.errors);
    }
  };

  useEffect(() => {
    if (onError && hasErrors(formState.submitErrors)) {
      onError(formState.submitErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitErrors]);

  useEffect(() => {
    if (formState.submitSucceeded && props.restartOnSuccess) {
      // Typescript must be ignored here because the Final Form types aren't up to date with their api.
      // A pull request to Final Form has been issued: https://github.com/final-form/final-form/pull/354
      // Once it's merged in and we update to use the latest version, we can remove this ts-ignore.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formApi.restart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitSucceeded]);

  const violatesPristine = props.disallowPristineSubmit && formState.pristine;
  const violatesInvalid = props.disableOnInvalid && formState.invalid;
  const hasUnaddressedSubmissionErrors =
    formState.hasSubmitErrors && !formState.modifiedSinceLastSubmit;

  const isReady =
    !formState.hasValidationErrors &&
    !hasUnaddressedSubmissionErrors &&
    !formState.submitting &&
    !violatesPristine;

  const isDisabled =
    props.disabled ||
    formState.submitting ||
    violatesPristine ||
    violatesInvalid ||
    formState.submitSucceeded;

  return (
    <RhButton
      color={isReady ? "primary" : "default"}
      type="submit"
      fullWidth={props.fullWidth}
      disabled={isDisabled}
      onClick={handleClick}
      size={props.size}
    >
      {children}
    </RhButton>
  );
};
