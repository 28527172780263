import { ajax } from "@common/services/ajax";
import {
  CampaignPromoDataType,
  CampaignPromoType,
} from "@common/types/campaignTypes";

import { campaignPromosUrls } from "./campaignPromosUrls";

export const campaignPromosApi = {
  create: (
    campaignPromoData: CampaignPromoDataType
  ): Promise<CampaignPromoType> => {
    return ajax.post<CampaignPromoType, CampaignPromoDataType>(
      campaignPromosUrls.create(),
      campaignPromoData
    );
  },
  list: (): Promise<CampaignPromoType[]> =>
    ajax.get<CampaignPromoType[]>(campaignPromosUrls.list()),
};
